const LOG_PREFIX = '[OCM][StoryTellerV2] '
const CAIN_CDN_PATH = '//cdn.orangeclickmedia.com/tech/libs/deckard-cain-v2.js'
const uuid = require('uuid');
const Cookies = require("js-cookie");

module.exports = class StoryTellerV2 {
    utils
    config
    stConfig
    conditions
    stickyConfig
    selector
    expirationTime
    now

    constructor(utils, config) {
        this.stVisitedURLs = [];
        this.utils = utils;
        this.config = config;
        this.stConfig = config.services.story_teller_v2
        this.conditions = (utils.is_mobile) ? this.stConfig.mobile.conditions : this.stConfig.desktop.conditions // selector conditions
        this.stickyConfig = (utils.is_mobile) ? this.stConfig.mobile.sticky : this.stConfig.desktop.sticky
        this.stickyBottomHeight = 0; // keeps the visibility state of bottom sticky ad
        this.isIntersecting = false; // keeps the visibility state of the player
        this.isMounted = false;
        this.selector = null;
        this.now = new Date().getTime();
        let hours = 7 * 24 * 60 * 60 * 1000;
        this.expirationTime = this.now + hours;
        this.eId = uuid.v4();
        this.ANALYTICS_TIMEOUT = 15 * 60 * 1000;
    }

    run() {
        if (this.config.debug || this.stConfig.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        if(this.stConfig.enable_analytics){
            if (this.config.debug || this.stConfig.debug) {
                console.log(LOG_PREFIX + 'Analytics are enabled');
            }

            // Send previous events
            this.startTrackAnalytics();
        }

        // Get the visited stories from local storage and remove them
        this.getVisitedStories();

        // Set window global variables that will be used by the player
        this.setWindowGlobals();

        // Validate if player is allowed to current page type
        if (!this.utils.allowPageType(this.conditions.page_types)) {
            if (this.config.debug || this.stConfig.debug) {
                console.log(LOG_PREFIX + 'Page type not allowed, terminating process', this.conditions.page_types)
            }
            return
        }

        this.getStories().then(() => {

            this.filterStories()

            const wrapper = this.createWrapper()

            const node = this.utils.determineInjectionTarget(
                this.conditions.selector,
                this.conditions.position,
                this.conditions.count_gt,
                this.conditions.words,
                this.conditions.words_gt,
            )

            if (!node) {
                if (this.config.debug || this.stConfig.debug) {
                    console.info(LOG_PREFIX + 'selector NOT found, stopping process', node)
                }
                return;
            }

            if (wrapper) {
                this.utils.injectTag(node, wrapper, this.conditions.place)
                this.utils.waitFor('ADSQ.response', () => {
                    if ((this.config.debug || this.stConfig.debug) && this.utils.window?.ADSQ?.response) {
                        console.log(LOG_PREFIX + 'ADSQ.response', this.utils.window?.ADSQ?.response)
                    }
                    this.handleBottomSticky();
                    this.populateWrapper(wrapper)
                }, 50, () => {
                    // When no response is retrieved after 50 tries then populate
                    // the wrapper for rss source or when the ADSQ response is
                    // retrieved when failback is executed
                    if (
                        this.stConfig.source === 'rss' ||
                        (this.stConfig.source === 'adsquirrel' && this.utils.window?.ADSQ?.response)
                        (this.stConfig.source === 'adsquirrel' && this.stConfig.rss_url !== '')
                    ) {
                        this.handleBottomSticky();
                        this.populateWrapper(wrapper)
                    }
                })
            }
        }).catch(() => {
            this.utils.window.OCM.ST.stories = [];
        });
    }

    initAnalytics(){
        if(this.utils.window && this.utils.window.localStorage){
            try {

                // Get the previous analytics record
                const analytics = this.utils.window.localStorage.getItem('ost_analytics') ? JSON.parse(this.utils.window.localStorage.getItem('ost_analytics')) : [];

                // Push an empty entry for current page view
                analytics.push({
                    id: this.eId, // id
                    o: Cookies.get("_oid") || this.utils.window.localStorage.getItem('_oid') || null, // oid
                    oe: this.utils.window.OCM._oeid, // oeid
                    u: this.utils.window.location.href, // url
                    nc: [], // next click
                    pc: [], // previous click
                    vs: [], // viewable stories
                    ls: [], // loaded stories
                    r: [], // redirect
                    m: true, // mute
                    t: this.now // timestamp
                })
                if (this.config.debug || this.stConfig.debug) {
                    console.log(LOG_PREFIX + "Initializing track analytics: ", analytics);
                }

                // Update the analytics and store the analytics track id
                this.utils.window.localStorage.setItem('ost_analytics', JSON.stringify(analytics)); // store the initial analytics
                this.utils.window.sessionStorage.setItem('ost_aid', this.eId); // store the id for each page
            } catch (e) {

                // Push an empty entry for current page view
                const analytics = [{
                    id: this.eId, // id
                    o: Cookies.get("_oid") || this.utils.window.localStorage.getItem('_oid') || null, // oid
                    oe: this.utils.window.OCM._oeid, // oeid
                    u: this.utils.window.location.href, // url
                    nc: [], // next click
                    pc: [], // previous click
                    vs: [], // viewable stories
                    ls: [], // loaded stories
                    r: [], // redirect
                    m: true, // mute
                    t: this.now // timestamp
                }];
                if (this.config.debug || this.stConfig.debug) {
                    console.log(LOG_PREFIX + "Initializing track analytics: ", analytics);
                }

                // Update the analytics and store the analytics track id
                this.utils.window.localStorage.setItem('ost_analytics', JSON.stringify(analytics)); // store the initial analytics
                this.utils.window.sessionStorage.setItem('ost_aid', this.eId); // store the id for each page
            }
        }
    }

    startTrackAnalytics(){
        if(this.utils.window && this.utils.window.localStorage){
            // Get the previous analytics records
            const analytics = this.utils.window.localStorage.getItem('ost_analytics') ? JSON.parse(this.utils.window.localStorage.getItem('ost_analytics')) : [];
            let remainingAnalytics = [...analytics];
            let analyticsToPush = [];

            // Send the expired events and keep only the remaining ones that are still active
            for(let currentAnalytics of analytics){

                if((currentAnalytics.t + this.ANALYTICS_TIMEOUT) <= this.now){
                    remainingAnalytics = remainingAnalytics.filter(a => a.id !== currentAnalytics.id);

                    // Push analytics that have at least one event
                    if(currentAnalytics && ((currentAnalytics.nc && currentAnalytics.nc.length > 0) || (currentAnalytics.pc && currentAnalytics.pc.length > 0) || (currentAnalytics.vs && currentAnalytics.vs.length > 0) || (currentAnalytics.r && currentAnalytics.r.length > 0) || (currentAnalytics.ls && currentAnalytics.ls.length > 0))) {
                        analyticsToPush.push(currentAnalytics);
                    }
                }
            }

            if(analyticsToPush && analyticsToPush.length > 0) {
                fetch('https://storyteller-events-worker.ocm.workers.dev/st-events', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    mode: 'no-cors',
                    body: JSON.stringify({
                        events: analyticsToPush
                    })
                }).then((response) => {
                    // Update the analytics with the remaining that are not ready for storing
                    this.utils.window.localStorage.setItem('ost_analytics', JSON.stringify(remainingAnalytics));
                }).catch((err) => {
                    if (this.config.debug || this.stConfig.debug) {
                        console.log(LOG_PREFIX + "Failed to store st events: ", err);
                    }
                }).finally(() => {
                    // Initialize the analytics for current page view
                    this.initAnalytics();
                })
            } else {
                // Initialize the analytics for current page view
                this.initAnalytics();
            }
        }
    }

    getVisitedStories() {
        let cleanURL = this.utils.window.location.protocol + '//' + this.utils.window.location.hostname + this.utils.window.location.pathname
        let storedLocalData = this.utils.window.localStorage.getItem('osturls')

        //check if key exist, if no create
        if (!storedLocalData) {
            this.utils.window.localStorage.setItem(
                'osturls',
                JSON.stringify({
                    urls: this.stVisitedURLs,
                    exp: this.expirationTime,
                })
            )
            storedLocalData = this.utils.window.localStorage.getItem('osturls');
        }
        const storedData = JSON.parse(storedLocalData)

        //if 7d passed remove key
        if (storedData && this.now >= storedData.exp) {
            this.utils.window.localStorage.removeItem('osturls')
        }

        this.stVisitedURLs = storedData ? storedData.urls : [];
        if (this.utils.window.location.href.includes('ref=storyteller') && !this.stVisitedURLs.includes(cleanURL)) {
            this.stVisitedURLs.push(cleanURL)

            this.utils.window.localStorage.setItem(
                'osturls',
                JSON.stringify({
                    urls: this.stVisitedURLs,
                    exp: this.expirationTime,
                })
            )
        }
    }

    filterStories() {
        // if key exist, his.utils.window.OCM.ST.stories = tmp (if tmp.length > 4), else reset (show all feed from start)
        if (this.utils.window && this.utils.window.localStorage.getItem('osturls')) {
            const tmp = this.utils.window.OCM.ST.stories.filter((s) => !this.stVisitedURLs.includes(s.url))
            if (tmp.length > 4) {
                this.utils.window.OCM.ST.stories = JSON.parse(JSON.stringify(tmp))
            }
        }
    }

    getStories() {
        return new Promise((resolve, reject) => {
            if (this.stConfig.source === 'adsquirrel') {
                this.fetchStoriesFromAdsquirrel().then(() => {
                    resolve();
                }).catch(() => { // fallback to rss
                    if (this.config.debug || this.stConfig.debug) {
                        console.log(LOG_PREFIX + 'Failed fetchStoriesFromAdsquirrel, falling back to fetchStoriesFromRss')
                    }
                    this.fetchStoriesFromRss().then(() => {
                        resolve();
                    }).catch(() => {
                        if (this.config.debug || this.stConfig.debug) {
                            console.log(LOG_PREFIX + 'Failed fetchStoriesFromRss, falling back to cloud video')
                        }
                        reject();
                    })
                })
            } else if (this.stConfig.source === 'rss') {
                this.fetchStoriesFromRss().then(() => {
                    resolve();
                }).catch(() => {
                    if (this.config.debug || this.stConfig.debug) {
                        console.log(LOG_PREFIX + 'Failed fetchStoriesFromRss, falling back to cloud video')
                    }
                    reject();
                })
            } else {
                reject();
            }
        });
    }

    fetchStoriesFromRss() {
        return new Promise(async (resolve, reject) => {
            if (!this.stConfig.rss_url || this.stConfig.rss_url === '') {
                reject()
            }

            this.utils.parseRss(this.stConfig.rss_url).then((items) => {
                this.utils.window.OCM.ST.stories = []
                items.forEach((item) => {
                    if (this.config.debug || this.stConfig.debug) {
                        console.log(LOG_PREFIX + 'Pushing to stories [', item.title, item.link, item.image, ']')
                    }

                    this.utils.window.OCM.ST.stories.push({
                        url: item.link,
                        title: item.title,
                        img: item?.image
                    })
                })

                resolve()
            }).catch(() => {
                reject()
            })
        });
    }

    fetchStoriesFromAdsquirrel() {
        return new Promise((resolve, reject) => {
            this.utils.waitFor('ADSQ', () => {
                if (this.utils.window.ADSQ.response === null || this.utils.window.ADSQ.response.classified_as === null) {
                    if (this.config.debug || this.stConfig.debug) {
                        console.log(LOG_PREFIX + 'this.utils.window.ADSQ.response was null, rejecting fetchStoriesFromAdsquirrel')
                    }
                    reject();
                    return;
                }

                const hostname = 'hostname=' + this.utils.window.location.host + '&';
                const classification = 'classification=' + encodeURIComponent(this.utils.window.ADSQ.response.classified_as[0].split('/')[0]) + '&';

                let query = '';
                query += hostname;
                query += classification;
                query = query.substring(0, query.length - 1);
                query = btoa(query);

                fetch('https://api.adsquirrel.ai/stories?q=' + query, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'text/plain'
                    },
                    method: 'GET',
                })
                    .then((resp) => resp.json())
                    .then(data => {
                        this.utils.window.OCM.ST.stories = data?.filter(story => {
                            return story.url !== this.utils.window.location.protocol + '//' + this.utils.window.location.host + this.utils.window.location.pathname
                        })

                        if (typeof this.utils.window.OCM.ST.stories === 'undefined') {
                            if (this.config.debug || this.stConfig.debug) {
                                console.log(LOG_PREFIX + 'this.utils.window.OCM.ST.stories was undefined, rejecting fetchStoriesFromAdsquirrel')
                            }
                            reject()
                        }

                        if (this.config.debug || this.stConfig.debug) {
                            console.log(LOG_PREFIX + 'this.utils.window.OCM.ST.stories is gg, resolving fetchStoriesFromAdsquirrel')
                        }
                        resolve();
                    }).catch(() => {
                    if (this.config.debug || this.stConfig.debug) {
                        console.log(LOG_PREFIX + 'Could not fetch stories from api, rejecting fetchStoriesFromAdsquirrel')
                    }
                    reject();
                })
            }, 100, () => {
                if (this.config.debug || this.stConfig.debug) {
                    console.log(LOG_PREFIX + 'ADSQ response took too long to respond, rejecting fetchStoriesFromAdsquirrel')
                }
                reject()
            })
        })
    }

    setWindowGlobals() {
        if (this.config.debug || this.stConfig.debug) {
            console.log(LOG_PREFIX + 'Setting window globals');
        }

        this.utils.window.OCM.ST = {
            midRollTime: this.stConfig.midRollTime || 5,
            playerTagId: this.stConfig.playerTagId,
            publisherId: this.stConfig.publisherId,
            source: this.stConfig.source,
            rss_url: this.stConfig.rss_url,
            debug: (this.config.debug || this.stConfig.debug),
            redirect_target: this.stConfig.redirect_target,
            branding: this.stConfig.branding,
            sticky: this.stickyConfig,
            device: this.utils.is_mobile ? 'mobile' : 'desktop',
            enable_analytics: this.stConfig.enable_analytics || false,
        }
    }

    createWrapper() {
        if (this.config.debug || this.stConfig.debug) {
            console.log(LOG_PREFIX + 'Creating wrapper')
        }

        const wrapper = this.utils.window.document.createElement('div')
        wrapper.setAttribute('data-id', 'ocm-st');
        this.utils.loadStyle(`div[data-id="ocm-st"] { max-width:640px; display:block; margin:0 auto 1rem; position: relative; }`)

        const styles = (this.utils.is_mobile) ? this.stConfig.mobile.styles : this.stConfig.desktop.styles
        const classLists = (this.utils.is_mobile) ? this.stConfig.mobile.classes : this.stConfig.desktop.classes

        if (styles && styles !== '') {
            wrapper.style = styles
        }

        if (classLists && classLists !== '') {
            wrapper.setAttribute('class', classLists)
        }

        return wrapper
    }

    /**
     * Track if the bottom sticky ad is visible or not. If the bottom sticky ad is visible then we will move
     * higher the sticky storyteller player to avoid overlapping
     */

    handleBottomSticky() {
        const observer = new MutationObserver((mutationsList, observer) => {
            const target = this.utils.window.document.querySelector('.ocm-sticky.bottom');
            this.stickyBottomHeight = target && target.clientHeight || 0;
        });
        observer.observe(this.utils.window.document, {childList: true, subtree: true});
    }

    handleStickiness(frame, wrapper, isIntersecting) {
        const isSticky = this.stickyConfig && this.stickyConfig.enable;
        const isFullWidthPlayer = this.stickyConfig?.full_width;

        if (!this.isMounted) {
            return;
        }

        if (isSticky) {
            if (!isIntersecting) {
                frame.style.position = 'fixed';
                frame.classList.add('ocm-st-sticky');
                frame.style.zIndex = '2147483646';

                if (isFullWidthPlayer && wrapper.clientWidth < 425) { // full width player
                    frame.style.width = '100%';
                    const height = this.utils.is_mobile ? (wrapper.clientWidth * 0.4 > 120 ? wrapper.clientWidth * 0.4 : 120) : wrapper.clientWidth * 0.5;
                    frame.style.height = height + 'px';

                    if (this.stickyConfig.position === 'bottom_right' || this.stickyConfig.position === 'bottom_left') {
                        frame.style.bottom = this.stickyConfig.offsets.bottom + 'px';
                        frame.style.left = '0%';
                    } else if (this.stickyConfig.position === 'top_right' || this.stickyConfig.position === 'top_left') {
                        frame.style.top = this.stickyConfig.offsets.top + 'px';
                        frame.style.left = '0%';
                    }
                } else { // floating player
                    const width = this.utils.is_mobile ? (wrapper.clientWidth * 0.4 > 288 ? wrapper.clientWidth * 0.4 : 288) : wrapper.clientWidth * 0.5;
                    const height = Math.ceil((width * 9) / 16) + 70; // we add extra padding for the controls
                    frame.style.width = width + 'px';
                    frame.style.height = height + 'px';

                    if (this.stickyConfig.position === 'bottom_right') {
                        frame.style.bottom = (+this.stickyConfig.offsets.bottom + this.stickyBottomHeight) + 'px';
                        frame.style.right = this.stickyConfig.offsets.right + 'px';
                    } else if (this.stickyConfig.position === 'bottom_left') {
                        frame.style.bottom = (+this.stickyConfig.offsets.bottom + this.stickyBottomHeight) + 'px';
                        frame.style.left = this.stickyConfig.offsets.left + 'px';
                    } else if (this.stickyConfig.position === 'top_right') {
                        frame.style.top = this.stickyConfig.offsets.top + 'px';
                        frame.style.right = this.stickyConfig.offsets.right + 'px';
                    } else if (this.stickyConfig.position === 'top_left') {
                        frame.style.top = this.stickyConfig.offsets.top + 'px';
                        frame.style.left = this.stickyConfig.offsets.left + 'px';
                    }
                }
            } else { // when the player is visible at the viewport
                const width = wrapper.clientWidth;
                const height = Math.ceil((width * 9) / 16) + 70; // we add extra padding for the controls
                if (this.config.debug || this.stConfig.debug) {
                    console.log(LOG_PREFIX + 'Width ', width, ' and height: ', height);
                }
                wrapper.style.height = height + 'px'; // set a standard height for the wrapper
                frame.style.position = 'relative';
                frame.classList.remove('ocm-st-sticky');
                frame.style.zIndex = '0';
                frame.style.width = '100%';
                frame.style.height = height + 'px';
                frame.style.bottom = "";
                frame.style.top = "";
                frame.style.left = "";
                frame.style.right = "";
            }
        }
    }

    appendIframeContent(frame) {
        const doc = frame.contentDocument || frame.contentWindow.document;

        const div = doc.createElement('div');
        div.id = 'ocm-st';

        const script = doc.createElement('script');
        script.src = CAIN_CDN_PATH;

        doc.body.insertAdjacentElement('afterbegin', div);
        doc.body.insertAdjacentElement('beforeend', script);
    }

    populateWrapper(wrapper) {
        if (this.config.debug || this.stConfig.debug) {
            console.log(LOG_PREFIX + 'Populating wrapper for the iframe');
        }

        const width = wrapper.clientWidth;
        const height = Math.floor(width / 1.28);

        if (this.config.debug || this.stConfig.debug) {
            console.log(LOG_PREFIX + 'Populating wrapper for the iframe with width: ', width, ' and height: ', height);
        }

        // Create an iframe
        const frame = this.utils.window.document.createElement('iframe');
        frame.setAttribute('id', 'ocm-st-frame');
        frame.setAttribute('FRAMEBORDER', 0);
        frame.setAttribute('SCROLLING', 'no');
        frame.setAttribute('MARGINHEIGHT', 0);
        frame.setAttribute('MARGINWIDTH', 0);
        frame.setAttribute('TOPMARGIN', 0);
        frame.setAttribute('LEFTMARGIN', 0);
        frame.setAttribute('ALLOWTRANSPARENCY', 'true');
        wrapper.style.height = height + 'px'; // set a standard height for the wrapper
        frame.style.height = height + 'px'; // decide the height based on the wrapper dimensions
        frame.style.width = '100%';

        // Append iframe to wrapper
        wrapper.appendChild(frame);

        if (this.stConfig.branding && this.stConfig.branding.custom_css && this.stConfig.branding.custom_css !== '') {
            const styleEl = frame.contentWindow.document.createElement('style');
            styleEl.appendChild(document.createTextNode(this.stConfig.branding.custom_css));
            frame.contentWindow.document.head.appendChild(styleEl);
        }

        const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
        if (isFirefox) {
            frame.onload = () => {
                this.appendIframeContent(frame);
            }
        } else {
            this.appendIframeContent(frame);
        }
        
        new IntersectionObserver((entries) => {
            this.isIntersecting = entries && entries.length > 0 && entries[0]?.isIntersecting;

            // Inform the player iframe about stickiness
            frame.contentWindow.postMessage({
                channel: 'storyteller',
                event: 'isIntersecting',
                value: this.isIntersecting
            });

            if (this.config.debug || this.stConfig.debug) {
                console.log(LOG_PREFIX + 'Observer was triggered and we inform the iframe with the following event: ', {
                    channel: 'storyteller',
                    event: 'isIntersecting',
                    value: this.isIntersecting
                });
            }
        }, {root: null, threshold: 0}).observe(wrapper);

        this.utils.window.addEventListener("message", (event) => {
            const data = event.data;
            if (data?.channel === 'storyteller' && data?.event === 'isSticky') {
                const isSticky = data?.value;
                this.handleStickiness(frame, wrapper, !isSticky);
                if (this.config.debug || this.stConfig.debug) {
                    console.log(LOG_PREFIX + 'Parent received sticky event: ', data);
                }
            }

            // Inform about the stickiness when the player mounts
            if (data?.channel === 'storyteller' && data?.event === 'onMounted') {
                frame.contentWindow.postMessage({
                    channel: 'storyteller',
                    event: 'isIntersecting',
                    value: this.isIntersecting
                });
                this.isMounted = true;
                if (this.config.debug || this.stConfig.debug) {
                    console.log(LOG_PREFIX + "The player inside the iframe was mounted successfully")
                }
            }

            // When we receive a restart event we restart the entire service
            if(data?.channel === 'storyteller' && data?.event === 'restart'){
                this.restart();
            }
        });
    }

    restart(){
        const wrapper = document.querySelector('div[data-id="ocm-st"]');
        if(wrapper){
            if (this.config.debug || this.stConfig.debug) {
                console.log(LOG_PREFIX + 'Removing the wrapper and restarting storyteller.');
            }
            wrapper.remove();
            this.run();
        }
    }
}
