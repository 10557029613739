const LOG_PREFIX = "[OCM][PageSense] "
let pageSense = async (utils) => {
  return new Promise((resolve, reject) => {
    let pageType = 'ROS';
    let subPaths = window.location.pathname.replace("/", "").split("/") || [];
    let customPaths = [];
    // Keep only String values in subPaths (removes null and empty)
    subPaths = subPaths.filter(String);
    // The actual page sense goes here

    if (subPaths.length === 0) {
      pageType = "wp-homepage";
    } else if (subPaths.length === 1) {
      if (customPaths.indexOf(subPaths[0]) >= 0) {
        pageType = "Custom-1";
      } else if (subPaths[0] === "forum") {
        pageType = "forum-homepage";
      }
    } else if (subPaths.length === 2){
      if (subPaths[0] === "category") {
        pageType = "wp-category";
      } else if (subPaths[0] === "tag") {
        pageType = "wp-tag";
      } else if (subPaths[0] === "forum" || subPaths[1] === "search") {
        pageType = "forum-search";
      }
    } else if (subPaths.length === 3){
      if (subPaths[0] === "category") {
        pageType = "wp-category";
      } else if (subPaths[0] === "forum" || subPaths[1] === "forum") {
        pageType = "forum-category";
      }  else if (subPaths[0] === "forum" || subPaths[1] === "topic") {
        pageType = "forum-posts";
      }
    } else if (subPaths.length >= 4){
      if (subPaths[0] === "forum" || subPaths[1] === "forum") {
        pageType = "forum-category";
      }  else {
        pageType = "wp-article";
      }
    }
    window.googletag = window.googletag || {cmd: []}

    utils.contentLoaded().then(() => {

      // GAM targeting
      window.googletag.cmd.push(() => {
        googletag.pubads().setTargeting('page', [pageType])
      })
      // Slot definitions
      defineSlots(utils, pageType)
      // Ad div detection and display
      let ad_placements = document.querySelectorAll('div[data-ocm-ad]')
      ad_placements.forEach((ap) => {
        window.googletag.cmd.push(() => { googletag.display(ap.id) })
      })
    })

    utils.loadStyle('#org_wp_articles_b {display:block !important;} #org_wp_articles_b::before {content: "Διαφημίσεις";text-align: center; display: block;padding: 5px 0px 5px 0px;font-size: 0.6em;color: #ccc;width: 100%;}');
    utils.loadStyle('.mainpost div#ocm-outstream, .mainpost div.teads-adCall, .mainpost div#aniBox, .mainpost div.ocm-player{ display:block; }')

    resolve(pageType)
  });
};
let defineSlots = (utils, pageType) => {

  window.googletag.cmd.push(() => {


    var mobile_sticky = googletag.sizeMapping()
    .addSize([0, 0], [[320,100],[320,50]])
    .addSize([340,0], [[320,100],[320,50]])
    .addSize([768, 0], [])
    .addSize([1024, 0],[])
    .build();

    var wp_home_bg = googletag.sizeMapping()
    .addSize([0, 0], [[300,600],[300,250]])
    .addSize([340,0], [[300,600],[300,250],[336,280],[320,480]])
    .addSize([768, 0], [[300,600]])
    .addSize([1024, 0],[[300,600]])
    .build();

    var wp_home_sm = googletag.sizeMapping()
    .addSize([0, 0], [[300,600],[300,250]])
    .addSize([340,0], [[300,600],[300,250],[336,280],[320,480]])
    .addSize([768, 0], [[300,250]])
    .addSize([1024, 0],[[300,250]])
    .build();

    var category_sidebar = googletag.sizeMapping()
    .addSize([0, 0], [[300,600],[300,250]])
    .addSize([340,0], [[300,600],[300,250],[336,280],[320,480]])
    .addSize([768, 0], [[300,600]])
    .addSize([1024, 0],[[300,600]])
    .build();

    var category = googletag.sizeMapping()
    .addSize([0, 0], [[300,600],[300,250]])
    .addSize([340,0], [[300,600],[300,250],[336,280],[320,480]])
    .addSize([768, 0], [[728,90]])
    .addSize([1024, 0],[[728,90]])
    .build();

    var article = googletag.sizeMapping()
    .addSize([0, 0], [[300,600],[300,250]])
    .addSize([340,0], [[300,600],[300,250],[336,280],[320,480]])
    .addSize([768, 0], [[300,250],[336,280],[320,480]])
    .addSize([1024, 0],[[728,90],[300,250],[336,280],[320,480]])
    .build();

    var forum_hp = googletag.sizeMapping()
    .addSize([0, 0], [[300,600],[300,250]])
    .addSize([340,0], [[300,600],[300,250],[336,280],[320,480]])
    .addSize([768, 0], [[728,90],[300,250],[336,280],[320,480]])
    .addSize([1024, 0],[[970,250],[970,90],[728,90],[300,250],[336,280],[320,480]])
    .build();

    if (pageType==="wp-homepage") {
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_wp_extra',[[300,250]],'org_wp_extra').addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_wp_home_a',[[300,600],[300,250],[336,280],[320,480]],'org_wp_home_a').defineSizeMapping(wp_home_bg).addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_wp_home_b',[[300,600],[300,250],[336,280],[320,480]],'org_wp_home_b').defineSizeMapping(wp_home_sm).addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_wp_home_c',[[300,600],[300,250],[336,280],[320,480]],'org_wp_home_c').defineSizeMapping(wp_home_bg).addService(googletag.pubads());
    }

    if (pageType==="wp-category" || pageType==="wp-tag") {
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_wp_extra',[[300,250]],'org_wp_extra').addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_wp_category_a',[[728,90],[300,600],[300,250],[336,280],[320,480]],'org_wp_category_a').defineSizeMapping(category).addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_wp_category_b',[[300,600],[300,250],[336,280],[320,480]],'org_wp_category_b').defineSizeMapping(category_sidebar).addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_wp_category_c',[[300,600],[300,250],[336,280],[320,480]],'org_wp_category_c').defineSizeMapping(category_sidebar).addService(googletag.pubads());
    }

    if (pageType==="wp-article") {
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_wp_extra',[[300,250]],'org_wp_extra').addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_wp_articles_a',[[728,90],[300,600],[300,250],[336,280],[320,480]],'org_wp_articles_a').defineSizeMapping(article).addService(googletag.pubads());
    }

    if (pageType==="forum-homepage" || pageType==="forum-posts") {
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_forum_category_a',[[970,250],[970,90],[728,90],[300,600],[300,250],[336,280],[320,480]],'org_forum_category_a').defineSizeMapping(forum_hp).addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_forum_category_b',[[970,250],[970,90],[728,90],[300,600],[300,250],[336,280],[320,480]],'org_forum_category_b').defineSizeMapping(forum_hp).addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_forum_category_c',[[970,250],[970,90],[728,90],[300,600],[300,250],[336,280],[320,480]],'org_forum_category_c').defineSizeMapping(forum_hp).addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_forum_category_d',[[970,250],[970,90],[728,90],[300,600],[300,250],[336,280],[320,480]],'org_forum_category_d').defineSizeMapping(forum_hp).addService(googletag.pubads());
    }

    if (pageType==="forum-category") {
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_forum_category_a',[[970,250],[970,90],[728,90],[300,600],[300,250],[336,280],[320,480]],'org_forum_category_a').defineSizeMapping(forum_hp).addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_forum_category_b',[[970,250],[970,90],[728,90],[300,600],[300,250],[336,280],[320,480]],'org_forum_category_b').defineSizeMapping(forum_hp).addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_forum_category_c',[[970,250],[970,90],[728,90],[300,600],[300,250],[336,280],[320,480]],'org_forum_category_c').defineSizeMapping(forum_hp).addService(googletag.pubads());
      googletag.defineSlot('/75351959,133251087/parents.org.gr/org_forum_category_d',[[970,250],[970,90],[728,90],[300,600],[300,250],[336,280],[320,480]],'org_forum_category_d').defineSizeMapping(forum_hp).addService(googletag.pubads());
    }

  })
}
module.exports = pageSense;