const LOG_PREFIX = '[OCM][Outbrain] '

module.exports = class Outbrain {
    utils
    config
    ob_config

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.ob_config = (this.utils.is_mobile) ? config.services.outbrain.mobile : config.services.outbrain.desktop
        this.is_injected = false;
    }

    run() {
        if (!this.ob_config.enable) {
            return;
        }

        if (this.config.debug || this.config.services.outbrain.debug) {
            console.log(LOG_PREFIX + 'Running...');
        }

        if (this.ob_config.lazyload && this.ob_config.lazyload.enable) {
            const selector = this.utils.window.document.querySelector(this.ob_config.conditions.selector);
            if (selector) {
                this.utils.lazyLoad(
                    this.ob_config.conditions.selector,
                    {rootMargin: `0px 0px ${(this.ob_config.lazyload && this.ob_config.lazyload.offset && this.ob_config.lazyload.offset !== '' ? this.ob_config.lazyload.offset : 0)}px 0px`},
                    (entry, observer) => {
                        if (!this.is_injected) {
                            observer.unobserve(entry.target);
                            this.is_injected = true;
                            this.inject();
                        }
                    }
                )
            } else {
                if (this.config.debug || this.config.services.outbrain.debug) {
                    console.log(LOG_PREFIX + 'Could not find defined selector: ' + this.ob_config.selector);
                }
            }
        } else {
            this.inject();
        }
    }

    inject() {
        let node = this.utils.determineInjectionTarget(this.ob_config.conditions.selector, this.ob_config.conditions.position, this.ob_config.conditions.count_gt, this.ob_config.conditions.word_count, this.ob_config.conditions.words_gt)
        if (node) {
            let widget = document.createElement('div')
            widget.classList.add('OUTBRAIN')
            if((this.ob_config && this.ob_config.include_ob_content_url === undefined) || this.ob_config.include_ob_content_url){
                widget.setAttribute('data-ob-contentUrl', window.location.href)
            }
            widget.setAttribute('data-widget-id', this.ob_config.widgetId)
            widget.setAttribute('data-ob-installation-key', this.ob_config.installationKey)

            const styles = this.ob_config.styles;
            const classes = this.ob_config.classes.split(' ');

            if (styles && styles !== '') {
                widget.setAttribute('style', styles);
            }

            if (classes.length) {
                widget.classList.add('OUTBRAIN');
                widget.classList.add(classes);
            }

            let script = document.createElement('script')
            script.async = 'async';
            script.src = 'https://widgets.outbrain.com/outbrain.js';

            if (this.utils.injectTag(node, widget, this.ob_config.conditions.place)) {
                if (this.config.debug || this.config.services.outbrain.debug) {
                    console.log(LOG_PREFIX + 'Injected OUTBRAIN div in ' + this.ob_config.conditions.place + ' of ', node);
                }

                if (this.utils.injectTag(node, script, this.ob_config.conditions.place)) {
                    if (this.config.debug || this.config.services.outbrain.debug) {
                        console.log(LOG_PREFIX + 'Injected OUTBRAIN script in ' + this.ob_config.conditions.place + ' of ', node);
                    }
                }
            }
        } else {
            if (this.config.debug || this.ob_config.outbrain.debug) {
                console.log(LOG_PREFIX + 'Could not find defined selector: ' + this.ob_config.selector);
            }
        }
    }
}
