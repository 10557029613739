const LOG_PREFIX = "[OCM][Custom] "
const Aniview = require('../../services/aniview')
let customCode = (utils) => {
  return new Promise((resolve, reject) => {
    utils.contentLoaded().then(() => {
      //runOutstream(utils)
      ocmGoneMadHb(utils)
    })
    resolve(true)
  });
};

let runOutstream = (utils) => {
  if (utils.config.debug) {
    console.log(LOG_PREFIX + 'Running outstream')
  }

  (function (w, d, s) {
    try {
      d = w.top.document || d;
      w = w.top.document ? w.top : w;
    } catch (e) {
    }

    if (w.teads !== undefined) {
      return;
    }

    let exclude_urls = [
      //{'url': '2935139'}
    ]

    for (var z = 0; z < exclude_urls.length; z++) {
      if (w.location.href.indexOf(exclude_urls[z].url) > -1) {
        return;
      }
    }

    var selector = document.querySelectorAll(".mainpost p")[2];

    if (window.OCM.pageType === "forum-category") {
      selector = document.querySelectorAll("#elPostFeed article, #ipsLayout_mainArea ol li")[0];
    }

    if (!selector) {
      return
    }

    var ttag = function () {
      w.teads.page(199646).placement(215557, {
        slider: {allow_corner_position: false, allow_top_position: false},
        "css": "width:100% !important; height:auto !important; margin: 10px auto 25px; clear: both;",
        "format": "inread",
        "slot": {"insertAfter": true, "btf": false, "selector": selector, "minimum": 0}
      }).passback(function () {
        window.top.OCM.oipb = () => {
          ocmPassback(utils, selector)
        }

        if (window.OCM.pageType === "forum-category") {
          utils.config.services.aniview.mobile.rules.selector = "#elPostFeed article, #ipsLayout_mainArea ol li"
          utils.config.services.aniview.desktop.rules.selector = "#elPostFeed article, #ipsLayout_mainArea ol li"
          utils.config.services.aniview.mobile.rules.position = 0
          utils.config.services.aniview.desktop.rules.position = 0
          utils.config.services.aniview.mobile.rules.count_gt = 0
          utils.config.services.aniview.desktop.rules.count_gt = 0
        } else {
          utils.config.services.aniview.mobile.rules.selector = ".mainpost p,#elPostFeed article"
          utils.config.services.aniview.desktop.rules.selector = ".mainpost p,#elPostFeed article"
          utils.config.services.aniview.mobile.rules.position = 2
          utils.config.services.aniview.desktop.rules.position = 2
          utils.config.services.aniview.mobile.rules.count_gt = 3
          utils.config.services.aniview.desktop.rules.count_gt = 3
        }

        utils.config.services.aniview.active = true
        const av = new Aniview(utils, utils.config)
        av.run()

      }).serve();
    };
    if (w.teads && w.teads.page) {
      ttag();
    } else if (!w.teadsscript) {
      var protocol = w.location.protocol.match(/^https?:$/) ? w.location.protocol : 'https:';
      s.src = protocol + '//a.teads.tv/media/format/v3/teads-format.min.js';
      s.async = true;
      s.onload = ttag;
      w.teadsscript = d.getElementsByTagName('head')[0].appendChild(s);
    }
  })(window, document, document.createElement('script'));
}

let ocmPassback = (utils, selector) => {
  if (!selector) {
    return
  }

  window.googletag.cmd.push(() => {
    let  inread = googletag.sizeMapping()
    .addSize([0,0], [[300,250]])
    .addSize([340,0], [[300,250],[336, 280]])
    .addSize([1024, 0], [[640,480],[640,360],[300,250],[336,280]])
    .build();

    googletag.defineSlot('/75351959,133251087/parents.org.gr/inread_pb', [[300, 250],[336, 280],[640,480],[640,360]], 'ocm-outstream').defineSizeMapping(inread).addService(googletag.pubads());

    selector.insertAdjacentHTML('afterend', `<div id="ocm-outstream" style="margin-bottom: 26px;margin-top: 26px;text-align:center; clear: both;"></div>`)
    let div = document.getElementById('ocm-outstream')
    let script = document.createElement('script')
    script.text = `googletag.cmd.push(() => { googletag.display('ocm-outstream'); })`
    div.append(script)
  })
}


let ocmGoneMadHb = (utils) => {
  let waitForVariable = (utils.config.services.header_bidding.adsqRTDModuleEnabled) ? 'ADSQ' : 'ocmpbjs'
  utils.waitFor(waitForVariable, () => {
   
    // inject a simple div
    let div = `<div id="ocm-gone-mad"></div>`
    let target = utils.doc_body || document.body
    target.insertAdjacentHTML('beforeEnd', div)

    let adunit = window.OCM.ad_units.filter((ad) => {
      return (ad.code === 'ocm-gone-mad')
    })[0]

    // Auction for it
    window.ocmpbjs.que.push(() => {
      window.ocmpbjs.addAdUnits({
        code: adunit.code,
        mediaTypes: adunit.mediaTypes,
        bids: adunit.bids,
      })

      window.ocmpbjs.requestBids({
        adUnitCodes: ['ocm-gone-mad'],
        bidsBackHandler: (bidResponses, timeout, auctionId) => {
          console.log('bidResponses', bidResponses)
          // filter out rejected bids
          Object.keys(bidResponses).forEach(adUnit => {
            bidResponses[adUnit].bids = bidResponses[adUnit].bids.filter(b => b.statusMessage !== 'bidRejected' && b.status !== 'bidRejected');
          });

          // filter out empty bids
          Object.keys(bidResponses).forEach(adUnit => {
            if (!bidResponses[adUnit].bids.length) {
              delete bidResponses[adUnit]
            }
          });

          if (Object.keys(bidResponses).length > 0) {
            console.log('bidResponses before directRenderBids', bidResponses)
            directRenderBids(bidResponses, utils)
          }
        },
        timeout: utils.config.services.header_bidding.bidderTimeout
      });
    })
  })
}

let directRenderBids = (bidResponses, utils) => {
  for (let adUnitCode in bidResponses) {
    if (utils.config.debug || utils.config.services.header_bidding.debug) {
      console.log(LOG_PREFIX + 'Found bid for #' + adUnitCode)
    }

    const divId = adUnitCode;
    const adDiv = window.document.getElementById(divId)

    if (adDiv) {
      if (utils.config.debug || utils.config.services.header_bidding.debug) {
        console.log(LOG_PREFIX + 'Ad div = ', adDiv)
      }

      let frmEl = window.document.createElement('iframe');

      frmEl.setAttribute('id', divId + '_ocm_hb')
      frmEl.setAttribute('style', 'border:0;overflow:hidden;margin:15px auto;display:block;');
      frmEl.scrolling = 'no'
      frmEl.width = '0'
      frmEl.height = '0'

      adDiv.appendChild(frmEl);

      let iframeDoc = frmEl.contentWindow.document
      let adServerTargeting = window.ocmpbjs.getAdserverTargetingForAdUnitCode(divId)
      //       if (utils.config.debug || utils.config.services.header_bidding.debug) {
      console.log(LOG_PREFIX + 'adServerTargeting', adServerTargeting)
      //       }

      // If any bidders return any creatives
      if (adServerTargeting && adServerTargeting['hb_adid']) {
        if (utils.config.debug || utils.config.services.header_bidding.debug) {
          console.log(LOG_PREFIX + 'Rendering ad for ' + divId)
        }
        window.ocmpbjs.renderAd(iframeDoc, adServerTargeting.hb_adid)
      }
    } else {
      if (utils.config.debug || utils.config.services.header_bidding.debug) {
        console.log(LOG_PREFIX + 'Ad div #' + divId + ' not found')
      }
    }
  }
}

module.exports = customCode;