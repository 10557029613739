const LOG_PREFIX = '[OCM][Injected Tags] '

module.exports = class InjectedTags {
    utils
    config
    it_config
    parent_el

    constructor(utils, config, parent_el = null) {
        // it could maybe help with infinite scrolling injections (new instance in customCode and use a different parent element)
        this.parent_el = parent_el

        this.utils = utils
        this.config = config
        this.it_config = config.services.injected_tags
    }

    run() {
        for (const tag of this.it_config.tags) {
            if (!tag.active) {
                continue;
            }

            if (this.utils.allowPageType(tag.conditions.page_types)) {
                this.createTag(tag);
            }
        }
    }

    /**
     * Creates the HTML tag and runs inject()
     * @param tag
     */
    createTag(tag) {
        this.utils.contentLoaded().then(() => {
            this.inject(tag);
        })
    }

    /**
     * Injects the
     * @param tag
     */
    inject(tag) {
        const tag_div_id = tag.div_id + '_' + this.utils.getRandomString(7)
        if (this.config.debug || this.it_config.debug) {
            console.log(LOG_PREFIX + 'injecting ' + tag.path)
        }

        if (tag.device_category !== undefined) {
            if (tag.device_category.mobile === false && this.utils.is_mobile) {
                if (this.config.debug || this.it_config.debug) {
                    console.info(LOG_PREFIX + 'injected tag: ' + tag.path + ' has been disabled on mobile')
                }
                return;
            }

            if (tag.device_category.desktop === false && !this.utils.is_mobile) {
                if (this.config.debug || this.it_config.debug) {
                    console.info(LOG_PREFIX + 'injected tag: ' + tag.path + ' has been disabled on desktop')
                }
                return;
            }
        }

        if (this.parent_el) {
            tag.conditions.selector = this.parent_el + ' ' + tag.conditions.selector
        }

        let node = this.utils.determineInjectionTarget(tag.conditions.selector, tag.conditions.position, tag.conditions.count_gt, tag.conditions.word_count, tag.conditions.words_gt)

        if (!node) {
            if (this.config.debug || this.it_config.debug) {
                console.info(LOG_PREFIX + 'node NOT found, stopping process', node)
            }
            return;
        }

        if (this.config.debug || this.it_config.debug) {
            console.info(LOG_PREFIX + 'node found: ', node)
        }

        let div = document.createElement('div');
        let div_child = document.createElement('div')
        let script = document.createElement('script');

        let is_mis = (tag.hasOwnProperty('mobile_inread_sticky') && this.utils.is_mobile) ? tag.mobile_inread_sticky : false

        if (is_mis) {
            div.classList.add('ocm-mis');

            let mis_expand = document.createElement('div')
            mis_expand.classList.add('ocm-mis-expand')

            let mis_wrapper = document.createElement('div')
            mis_wrapper.classList.add('ocm-mis-wrapper')

            div_child.id = tag_div_id
            div_child.setAttribute('data-ocm-mis', 'true')

            mis_wrapper.append(div_child)
            mis_expand.append(mis_wrapper)
            div.append(mis_expand)
        } else {
            div.id = tag_div_id;
        }

        if (tag.style !== '') {
            div.style = tag.style
        }

        if (tag.classes && typeof tag.classes !== 'undefined' && tag.classes !== '') {
            div.setAttribute('class', tag.classes)
        }

        if (tag.hasOwnProperty('size_mapping')
            && tag.size_mapping !== null
            && tag.size_mapping.hasOwnProperty('code')
            && tag.size_mapping.hasOwnProperty('name')
            && tag.size_mapping.code !== null
            && tag.size_mapping.name !== null) {
            script.text = 'googletag.cmd.push(function() { ' +
                ' ' + tag.size_mapping.code.replace(/g\n/, '') + ' ' +
                '  var ' + tag_div_id + '_slot = googletag.defineSlot("' + tag.path + '", ' + tag.sizes + ', "' + tag_div_id + '").defineSizeMapping(' + tag.size_mapping.name + ').addService(googletag.pubads());' +
                '  googletag.display("' + tag_div_id + '")' +
                '});';
            if (!this.config.services.lazyload.active && this.config.services.header_bidding.active) {
                script.text += 'if (window.OCM.init_ad_server_set) { googletag.pubads().refresh([' + tag_div_id + '_slot], { changeCorrelator: ' + this.config.change_correlator !== undefined ? this.config.change_correlator : true + ' }); }'
            }
        } else {
            script.text = 'googletag.cmd.push(function() { ' +
                '  var ' + tag_div_id + '_slot = googletag.defineSlot("' + tag.path + '", ' + tag.sizes + ', "' + tag_div_id + '").addService(googletag.pubads());' +
                '  googletag.display("' + tag_div_id + '") ' +
                '})';
            if (!this.config.services.lazyload.active && this.config.services.header_bidding.active) {
                script.text += 'if (window.OCM.init_ad_server_set) { googletag.pubads().refresh([' + tag_div_id + '_slot], { changeCorrelator: ' + this.config.change_correlator !== undefined ? this.config.change_correlator : true + ' }); }'
            }
        }

        if (tag.conditions.hasOwnProperty('place') && tag.conditions.place) {
            if (this.utils.injectTag(node, div, tag.conditions.place)) {
                if (is_mis) {
                    div_child.append(script)
                    this.utils.clipHiddenOverflows(div)
                } else {
                    div.append(script)
                }
            }
        }
    }
}
