const LOG_PREFIX = '[OCM][Sticky] '

module.exports = class Sticky {
    utils
    config
    sticky_config
    is_mobile
    counter
    skin_slot
    skin_empty

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.sticky_config = config.services.sticky
        this.counter = 0
        this.skin_slot = null
        this.skin_empty = null
    }

    async run() {
        if (this.config.debug || this.sticky_config.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        // Wait for googletag.pubadsready && DOMContentLoaded
        await this.utils.gptPubAdsReady()

        for (let ad of this.sticky_config.ads) {
            if (!ad.active) {
                continue
            }

            if (!this.deviceAllowed(ad.conditions) && ad.position !== 'fullscreen') {
                if (this.config.debug || this.sticky_config.debug) {
                    console.log(LOG_PREFIX + 'Ad (' + ad.code + ') not meant to run in current device')
                }
                continue
            }

            if (!this.utils.allowPageType(ad.conditions.page_types)) {
                if (this.config.debug || this.sticky_config.debug) {
                    console.log(LOG_PREFIX + 'Ad (' + ad.code + ') not meant to run in current page type')
                }
                continue
            }

            if (this.isExcluded(ad.conditions.exclude_urls)) {
                if (this.config.debug || this.sticky_config.debug) {
                    console.log(LOG_PREFIX + 'Ad (' + ad.code + '). Inside excluded url. Terminating service.')
                }
                continue
            }

            await this.structure(ad)
        }
    }

    async determineSkinExists() {
        return new Promise((resolve, reject) => {
            let ad_slots = []

            if (this.config.debug || this.sticky_config.debug) {
                console.log(LOG_PREFIX + 'Checking if skin ad exists')
            }

            if (this.skin_slot) {
                resolve(true);
            }

            ad_slots = this.utils.window.googletag.pubads().getSlots().filter((slot) => {
                if ((slot.getSlotElementId().toLowerCase().includes('skin') || slot.getAdUnitPath().toLowerCase().includes('skin'))) {
                    this.skin_slot = slot
                    return true
                }

                return false
            })

            resolve((ad_slots.length > 0))
        })
    }

    async structure(ad) {
        this.counter++

        if (this.config.debug || this.sticky_config.debug) {
            console.log(LOG_PREFIX + 'Structuring sticky with code: ', ad.code);
        }

        if (!ad.bg_color) {
            ad.bg_color = 'rgba(27,27,27,.75)'
        }

        // Explicitly exclude sticky ad from lazyload
        this.utils.config.services.lazyload.ad_load_offsets.push({
            path: ad.code,
            offset: -1
        })

        switch (ad.position) {
            case 'left':
                this.leftStyles(ad)
                await this.side(ad)
                break;
            case 'right':
                this.rightStyles(ad)
                await this.side(ad)
                break;
            case 'top':
                this.topStyles(ad)
                this.topBottom(ad)
                break;
            case 'bottom':
                this.bottomStyles(ad)
                this.topBottom(ad)
                break;
            case 'fullscreen':
                this.fullScreenStyles(ad);
                this.fullScreen(ad);
                break;
            default:
                break;
        }
    }

    async side(ad) {

        if (this.config.debug || this.sticky_config.debug) {
            console.log(LOG_PREFIX + 'Trying to render side sticky with code: ', ad.code);
        }

        this.styleForXButton();

        let isOpen = true
        let final_width
        let final_height

        let target = this.utils.window.document.querySelectorAll('body *')[0]

        let ocm_sticky = document.createElement('div')
        ocm_sticky.classList.add('ocm-sticky', ad.position)

        if (ad.style && ad.style !== '') {
            ocm_sticky.style = ad.style;
        }

        if (ad.classes && ad.classes !== '') {
            ocm_sticky.setAttribute('class', ad.classes)
        }

        let container = document.createElement('div')
        container.classList.add('ocm-sticky-container')

        let content = document.createElement('div')
        content.classList.add('ocm-sticky-content')

        let ad_div = document.createElement('div')
        ad_div.id = 'ocm_sticky_' + this.counter
        ad_div.classList.add('ocm-sticky-ad')

        let ad_script = document.createElement('script')
        ad_script.text = 'googletag.cmd.push(function() {' +
            'try { var ' + ad_div.id + '_slot = googletag.defineSlot("' + ad.code + '", ' + ad.sizes + ', "' + ad_div.id + '");' +
            'if (' + ad_div.id + '_slot) { ' +
            '  ' + ad_div.id + '_slot.addService(googletag.pubads());' +
            '  googletag.display("' + ad_div.id + '");' +
            '}} catch(err) {}'
        if (!this.config.services.lazyload.active && this.config.services.header_bidding.active) {
            ad_script.text += 'if (window.OCM.init_ad_server_set) { googletag.pubads().refresh([' + ad_div.id + '_slot], { changeCorrelator: ' + this.config.change_correlator !== undefined ? this.config.change_correlator : true + ' }); }'
        }
        ad_script.text += '});'

        // if (this.config.services.lazyload.active) {
        //     if (!this.config.services.lazyload.ad_load_offsets.filter((adunit) => {
        //         return (adunit.path === ad.code && adunit.offset === -1)
        //     }).length) {
        //         ad_div.setAttribute('data-lazyincluded-by-ocm', '');
        //         ad_div.setAttribute('data-oau-code', ad.code);
        //     }
        // }

        if (ad.size_mapping && ad.size_mapping.code && ad.size_mapping.name) {
            ad_script.text = 'googletag.cmd.push(function() { ' +
                ' ' + ad.size_mapping.code.replace(/g\n/, '') + ' ' +
                'googletag.defineSlot("' + ad.code + '", ' + ad.sizes + ', "' + ad_div.id + '").defineSizeMapping(' + ad.size_mapping.name + ').addService(googletag.pubads());' +
                '  googletag.display("' + ad_div.id + '")' +
                '});';
        }

        // let ocm_div = document.createElement('div')
        // ocm_div.classList.add('ocm-dm')
        // ocm_div.innerHTML = '<a href="https://orangeclickmedia.com" target="_blank">OCM Digital Advertising</a>'

        let close_container = document.createElement('div')
        close_container.classList.add('ocm-close-icon-container')

        let close_span = document.createElement('button')
        close_span.classList.add('ocm-button-close')

        if (!this.utils.is_mobile) {
            close_container.addEventListener('mouseenter', () => {
                close_span.classList.add('ocm-button-close-hover')

            })
            close_container.addEventListener('mouseleave', () => {
                close_span.classList.remove('ocm-button-close-hover')
            })
        }

        close_container.append(close_span)
        content.append(ad_div)
        container.append(content)
        ad_div.append(ad_script)
        ocm_sticky.append(container)
        target.parentNode.append(ocm_sticky)

        this.utils.window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
            if (this.config.debug || this.sticky_config.debug) {
                console.log(LOG_PREFIX + 'Slot render ended. Event: ', event, ' isEmpty: ', event.isEmpty, ' slotElementId: ', event.slot.getSlotElementId(), ' divId: ', ad_div.id);
            }
            if (!event.isEmpty && event.slot.getSlotElementId() === ad_div.id) {
                final_width = event.size[0]
                final_height = event.size[1]

                if (final_height === 1 && final_width === 1) {
                    return
                }

                ocm_sticky.style.width = String(final_width) + 'px'
                ocm_sticky.style.height = String(final_height) + 'px'
                ocm_sticky.style.marginTop = '-' + String(final_height / 2) + 'px'

                content.style.width = String(final_width) + 'px'
                content.style.height = String(final_height) + 'px'

                if (ad.conditions.retract) {
                    ocm_sticky.addEventListener('mouseenter', (e) => {
                        if (isOpen) {
                            return
                        }

                        if (ad.position === 'left') {
                            ocm_sticky.style.marginLeft = '0px'
                        } else if (ad.position === 'right') {
                            ocm_sticky.style.marginRight = '0px'
                        }

                        isOpen = true

                        e.stopPropagation();
                    })

                    ocm_sticky.addEventListener('mouseleave', (e) => {
                        if (!isOpen) {
                            return
                        }

                        setTimeout(() => {
                            if (ad.position === 'left') {
                                ocm_sticky.style.marginLeft = '-' + String(Math.round(final_width * 0.33)) + 'px'
                            } else if (ad.position === 'right') {
                                ocm_sticky.style.marginRight = '-' + String(Math.round(final_width * 0.33)) + 'px'
                            }
                            isOpen = false
                        }, 1000)

                        e.stopPropagation();
                    })
                }
            }
        })

        this.utils.window.googletag.pubads().addEventListener('impressionViewable', (event) => {
            if (this.config.debug || this.sticky_config.debug) {
                console.log(LOG_PREFIX + 'Event after impression viewable ' + event + ' event condition ' + event.slot.getSlotElementId() === ad_div.id)
            }
            if (event.slot.getSlotElementId() === ad_div.id) {

                // if there is no close button attach to ocm sticky then we append one
                const ocm_sticky_close_container = container && container.childNodes && Array.from(container.childNodes).find(el => el.className === 'ocm-close-icon-container')
                if (!ocm_sticky_close_container) {
                    container.append(close_container)
                }

                close_span.addEventListener('click', (e) => {
                    if (ad.position === 'left') {
                        ocm_sticky.style.marginLeft = '-' + String(final_width) + 'px'
                    } else if (ad.position === 'right') {
                        ocm_sticky.style.marginRight = '-' + String(final_width) + 'px'
                    }
                    setTimeout(() => {
                        ocm_sticky.remove()
                    }, 500)
                    e.stopPropagation();
                })

                if (ad.conditions.hide_if_skin) {
                    this.determineSkinExists().then((skin_exists) => {
                        if (skin_exists) {
                            if (this.skin_slot && this.skin_slot.getResponseInformation()) {
                                setTimeout(() => {
                                    if (ad.position === 'left') {
                                        ocm_sticky.style.marginLeft = '-400px'
                                    } else if (ad.position === 'right') {
                                        ocm_sticky.style.marginRight = '-400px'
                                    }
                                    setTimeout(() => {
                                        ocm_sticky.remove()
                                    }, 500)
                                }, 2000)
                            }
                            }
                    })
                }

                if (ad.conditions.retract && final_width !== 1 && final_height !== 1) {
                    setTimeout(function () {
                        if (ad.position === 'left') {
                            ocm_sticky.style.marginLeft = '-' + String(Math.round(final_width * 0.33)) + 'px'
                        } else if (ad.position === 'right') {
                            ocm_sticky.style.marginRight = '-' + String(Math.round(final_width * 0.33)) + 'px'
                        }

                        isOpen = false
                    }, 1000)
                }
            }
        })
    }

    topBottom(ad) {
        this.styleForXButton();

        let target = this.utils.window.document.querySelectorAll('body *')[0]

        // demo-sticky-mobile
        // let target = (ad.position === 'bottom') ? this.utils.window.document.querySelectorAll('.view > .iframe')[0]
        //     : this.utils.window.document.querySelectorAll('.view > .iframe > nav')[0]

        let container = document.createElement('div')
        container.classList.add('ocm-sticky', ad.position)

        if (ad.style && ad.style !== '') {
            container.style = ad.style;
        }

        if (ad.classes && ad.classes !== '') {
            container.setAttribute('class', ad.classes)
        }

        let ad_div = document.createElement('div')
        ad_div.id = 'ocm_sticky_' + this.counter
        // demo-sticky-mobile
        // this.utils.loadStyle('#ocm_sticky_' + this.counter + ' { display:flex }')

        let ad_script = document.createElement('script')
        ad_script.text = 'googletag.cmd.push(function() {' +
            '  try { var ' + ad_div.id + '_slot = googletag.defineSlot("' + ad.code + '", ' + ad.sizes + ', "' + ad_div.id + '");' +
            '  if (' + ad_div.id + '_slot) { ' +
            '    ' + ad_div.id + '_slot.addService(googletag.pubads());' +
            '    googletag.display("' + ad_div.id + '");' +
            '  }} catch(err) {}'
        if (!this.config.services.lazyload.active && this.config.services.header_bidding.active) {
            ad_script.text += 'if (window.OCM.init_ad_server_set) { googletag.pubads().refresh([' + ad_div.id + '_slot], { changeCorrelator: ' + this.config.change_correlator !== undefined ? this.config.change_correlator : true + ' }); }'
        }
        ad_script.text += '});'

        // if (this.config.services.lazyload.active) {
        //     ad_div.setAttribute('data-lazyincluded-by-ocm', '');
        //     ad_div.setAttribute('data-oau-code', ad.code);
        // }

        if (ad.size_mapping && ad.size_mapping.code && ad.size_mapping.name) {
            ad_script.text = 'googletag.cmd.push(function() { ' +
                ' ' + ad.size_mapping.code.replace(/g\n/, '') + ' ' +
                'googletag.defineSlot("' + ad.code + '", ' + ad.sizes + ', "' + ad_div.id + '").defineSizeMapping(' + ad.size_mapping.name + ').addService(googletag.pubads());' +
                '  googletag.display("' + ad_div.id + '")' +
                '});';
        }

        // let ocm_div = document.createElement('div')
        // ocm_div.classList.add('ocm-dm')
        // ocm_div.innerHTML = '<a href="https://orangeclickmedia.com" target="_blank">OCM Digital Advertising</a>'

        let close_container = document.createElement('div')
        close_container.classList.add('close-icon-container')

        let close_span = document.createElement('button')
        close_span.classList.add('ocm-button-close')

        if (!this.utils.is_mobile) {
            close_container.addEventListener('mouseenter', () => {
                close_span.classList.add('ocm-button-close-hover')

            })
            close_container.addEventListener('mouseleave', () => {
                close_span.classList.remove('ocm-button-close-hover')
            })
        }

        close_container.append(close_span)
        container.append(close_container)
        // container.append(ocm_div)
        ad_div.append(ad_script)
        container.append(ad_div)

        // demo-sticky-mobile
        // if (ad.position === 'bottom') {
        //     target.append(container)
        // } else {
        //     target.parentNode.insertBefore(container, target)
        // }

        target.parentNode.append(container);

        this.utils.window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
            if (!event.isEmpty && event.slot.getSlotElementId() === ad_div.id) {
                container.classList.add('reveal-' + ad.position)
                let width = event.size[0]
                let height = event.size[1]

                if (ad.conditions.device === 'desktop') {
                    width += 6
                    height += 6
                    if (!['top', 'bottom'].includes(ad.position)) {
                        container.style.width = String(width) + 'px'
                        container.style.height = String(height) + 'px'
                    }
                }

                setTimeout(() => {
                    close_container.addEventListener('click', () => {
                        container.classList.remove('reveal-' + ad.position)
                    })
                }, 2000)
            }
        })
    }

    fullScreen(ad){
        this.styleForXButton();

        const target = this.utils.window.document.querySelectorAll('body *')[0];

        const body = this.utils.window.document.querySelector('body');

        const ocm_sticky = this.utils.window.document.createElement('div');
        ocm_sticky.classList.add('ocm-sticky', 'ocm-fs');

        if (ad.style && ad.style !== '') {
            ocm_sticky.style = ad.style;
        }

        if (ad.classes && ad.classes !== '') {
            ocm_sticky.setAttribute('class', ad.classes);
        }

        const container = document.createElement('div');
        container.classList.add('ocm-sticky-container');

        const content = document.createElement('div');
        content.classList.add('ocm-sticky-content');

        const ad_div = this.utils.window.document.createElement('div');
        ad_div.id = 'ocm-sticky-fullscreen';
        ad_div.classList.add('ocm-sticky-ad');

        // if (this.config.services.lazyload.active) {
        //     ad_div.setAttribute('data-lazyincluded-by-ocm', '');
        //     ad_div.setAttribute('data-oau-code', ad.code);
        // }

        const close_container = document.createElement('div');
        close_container.classList.add('ocm-close-icon-container');

        const close_span = document.createElement('button');
        close_span.classList.add('ocm-button-close');

        if (!this.utils.is_mobile) {
            close_container.addEventListener('mouseenter', () => {
                close_span.classList.add('ocm-button-close-hover')

            });
            close_container.addEventListener('mouseleave', () => {
                close_span.classList.remove('ocm-button-close-hover')
            });
        }
        close_container.append(close_span);

        close_span.addEventListener('click', (e) => {
            setTimeout(() => {
                ocm_sticky.classList.remove("active");
                body.classList.remove('no-scroll');
            }, 500)
            e.stopPropagation();
        });


        content.append(ad_div);
        container.append(content);
        ocm_sticky.append(close_container);
        ocm_sticky.append(container);
        target.parentNode.append(ocm_sticky);

        this.utils.window.ocmpbjs = this.utils.window.ocmpbjs || {que: []};
        this.utils.window.ocmpbjs.que.push(() => {
            this.utils.window.ocmpbjs.onEvent('adRenderSucceeded', (bidResponse) => {
                if(bidResponse.bid.adUnitCode === ad.code){
                    ocm_sticky.classList.add('active');
                    body.classList.add('no-scroll');
                }
            });
        });
    }

    styleForXButton() {
        this.utils.loadStyle('' +
            '          .ocm-button-close{\n' +
            '               padding: 0 !important;\n' +
            '               border-radius: 4px !important;\n' +
            '               display: block !important;\n' +
            '               position: relative !important;\n' +
            '               width: 30px !important;\n' +
            '               height: 30px !important;\n' +
            '               background: #fff !important;\n' +
            '               color: #4a968e !important;\n' +
            '               border: 1px solid #106e89 !important;\n' +
            '               outline: none !important;\n' +
            '               cursor: pointer !important;\n' +
            '           }\n' +
            '\n' +
            '           .ocm-button-close-hover:before{\n' +
            '                   width: 100% !important;\n' +
            '                   box-sizing: border-box !important;\n' +
            '                   margin:0 !important;\n' +
            '                   padding:0 !important;\n' +
            '            }\n' +
            '\n' +
            '            .ocm-button-close-hover:after{\n' +
            '                   width: 100% !important;\n' +
            '                   box-sizing: border-box !important;\n' +
            '                   margin:0 !important;\n' +
            '                   padding:0 !important;\n' +
            '            }\n' +
            '\n' +
            '            .ocm-button-close:before{\n' +
            '               box-sizing: border-box !important;\n' +
            '               content: \'\' !important;\n' +
            '               border-radius: 13px !important;\n' +
            '               position: absolute !important;\n' +
            '               left: 50% !important;\n' +
            '               top: 50% !important;\n' +
            '               width: 0;\n' +
            '               margin:0 !important;\n' +
            '               padding:0 !important;\n' +
            '               height: 1px !important;\n' +
            '               border-left: 8px solid currentColor !important;\n' +
            '               border-right: 8px solid currentColor !important;\n' +
            '               -webkit-transform: translate3d(-50%,-50%,0) rotate(-45deg) !important;\n' +
            '               transition: all .2s cubic-bezier(.25,.46,.45,.94) !important;\n' +
            '           }\n' +
            '\n' +
            '           .ocm-button-close:after{\n' +
            '               box-sizing: border-box !important;\n' +
            '               content: \'\' !important;\n' +
            '               border-radius: 13px !important;\n' +
            '               position: absolute !important;\n' +
            '               left: 50% !important;\n' +
            '               top: 50% !important;\n' +
            '               width: 0;\n' +
            '               margin:0 !important;\n' +
            '               padding:0 !important;\n' +
            '               height: 1px !important;\n' +
            '               border-left: 8px solid currentColor !important;\n' +
            '               border-right: 8px solid currentColor !important;\n' +
            '               -webkit-transform: translate3d(-50%,-50%,0) rotate(45deg) !important;\n' +
            '               transition: all .2s cubic-bezier(.25,.46,.45,.94) !important;\n' +
            '           }'
        )
    }

    leftStyles(ad) {
        this.utils.loadStyle('' +
            '            .ocm-sticky.left {\n' +
            '                z-index: 2147483641;\n' +
            '                display: block;\n' +
            '                position: fixed;\n' +
            `                ${ad?.conditions?.bottom && ad?.conditions?.bottom !== '' ? 'bottom:' + ad?.conditions?.bottom + 'px;\n' : 'top:50%;\n'}` +
            '                left:0px;\n' +
            '                -webkit-transition: margin-left 0.5s ease-in-out !important;\n' +
            '                transition: margin-left 0.5s ease-in-out !important;\n' +
            '            }\n' +
            '            .ocm-sticky.left .ocm-sticky-container {\n' +
            '                z-index: 2147483643;\n' +
            '                background-color: #fff;\n' +
            '                border: 0 solid #afaeae;\n' +
            '                box-sizing: content-box;\n' +
            '                color: #000;\n' +
            '                height: 100%;\n' +
            '                overflow: visible;\n' +
            '                position: absolute;\n' +
            '                text-align: left;\n' +
            '                visibility: visible;\n' +
            '            }\n' +
            '            .ocm-sticky.left .ocm-sticky-container .ocm-sticky-content {\n' +
            '              overflow: hidden;\n' +
            '              visibility: visible;\n' +
            '            }\n' +
            '            .ocm-sticky.left .ocm-sticky-container .ocm-sticky-content .ocm-sticky-ad {\n' +
            '              border: 0;\n' +
            '              height:100%;\n' +
            '              visibility: visible;\n' +
            '              width:100%;\n' +
            '            }\n' +
            '            .ocm-sticky.left .ocm-sticky-container .ocm-close-icon-container {\n' +
            '              z-index: 2147483645;\n' +
            '              cursor: pointer;\n' +
            '              display: block;\n' +
            '              height: 31px;\n' +
            `              ${ad.x_position === 'left' ? 'left: 3px;': 'right: 3px;'}\n` +
            '              position: absolute;\n' +
            '              top: -35px;\n' +
            '              width: 32px;\n' +
            '            }\n' +
            '            .ocm-sticky.left .ocm-sticky-container .ocm-close-icon-container .ocm-close-icon .progress {\n' +
            '                opacity: 0;\n' +
            '                stroke-dasharray: 0,120;\n' +
            '                transition: 0.5s cubic-bezier(0.165, 0.775, 0.145, 1.020);\n' +
            '            }' +
            '')
    }

    rightStyles(ad) {
        this.utils.loadStyle('' +
            '            .ocm-sticky.right {\n' +
            '                z-index: 2147483641;\n' +
            '                display: block;\n' +
            '                position: fixed;\n' +
            `                ${ad?.conditions?.bottom && ad?.conditions?.bottom !== '' ? 'bottom:' + ad?.conditions?.bottom + 'px;\n' : 'top:50%;\n'}` +
            '                right:0px;\n' +
            '                -webkit-transition: margin-right 0.5s ease-in-out !important;\n' +
            '                transition: margin-right 0.5s ease-in-out !important;\n' +
            '            }\n' +
            '            .ocm-sticky.right .ocm-sticky-container {\n' +
            '                z-index: 2147483643;\n' +
            '                background-color: #fff;\n' +
            '                border: 0 solid #afaeae;\n' +
            '                box-sizing: content-box;\n' +
            '                color: #000;\n' +
            '                height: 100%;\n' +
            '                overflow: visible;\n' +
            '                position: absolute;\n' +
            '                text-align: left;\n' +
            '                visibility: visible;\n' +
            '            }\n' +
            '            .ocm-sticky.right .ocm-sticky-container .ocm-sticky-content {\n' +
            '              overflow: hidden;\n' +
            '              visibility: visible;\n' +
            '            }\n' +
            '            .ocm-sticky.right .ocm-sticky-container .ocm-sticky-content .ocm-sticky-ad {\n' +
            '              border: 0;\n' +
            '              height:100%;\n' +
            '              visibility: visible;\n' +
            '              width:100%;\n' +
            '            }\n' +
            '            .ocm-sticky.right .ocm-sticky-container .ocm-close-icon-container {\n' +
            '              z-index: 2147483645;\n' +
            '              cursor: pointer;\n' +
            '              display: block;\n' +
            '              height: 32px;\n' +
            `              ${ad.x_position === 'left' ? 'left: 3px;': 'right: 3px;'}\n` +
            '              position: absolute;\n' +
            '              top: -35px;\n' +
            '              width: 32px;\n' +
            '            }\n' +
            '            .ocm-sticky.right .ocm-sticky-container .ocm-close-icon-container .ocm-close-icon .progress {\n' +
            '                opacity: 0;\n' +
            '                stroke-dasharray: 0,120;\n' +
            '                transition: 0.5s cubic-bezier(0.165, 0.775, 0.145, 1.020);\n' +
            '            }' +
            '')
    }

    topStyles(ad) {
        this.utils.loadStyle('' +
            '          .ocm-sticky.top {\n' +
            '            position: fixed;\n' +
            // demo-sticky-mobile
            // '            position: sticky;\n' +
            '            background-color:' + ad.bg_color + ';\n' +
            '            width: 100%;\n' +
            '            padding: 8px 0;\n' +
            '            z-index: 2147483641;\n' +
            '            top: -1000px;\n' +
            '            left: 0;\n' +
            '            right: 0;\n' +
            '            transition: top .5s ease-in-out !important;\n' +
            '            text-align: center;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.reveal-top {\n' +
            '            top: 0;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.reveal-top div[data-oau-code] {\n' +
            '            margin: 0 auto;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.top .close-icon-container {\n' +
            '            position: absolute;\n' +
            '            bottom: -35px;\n' +
            `              ${ad.x_position === 'left' ? 'left: 4px;': 'right: 4px;'}\n` +
            '            z-index: 10;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.top .close-icon {\n' +
            '            display: inline-block;\n' +
            '            height: 32px;\n' +
            '            width: 32px;\n' +
            '            cursor: pointer;\n' +
            '            stroke: #fff;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.top .progress {\n' +
            '            opacity: 0;\n' +
            '            stroke-dasharray: 0, 120;\n' +
            '          }')
    }

    bottomStyles(ad) {
        this.utils.loadStyle('' +
            '          .ocm-sticky.bottom {\n' +
            '            position: fixed;\n' +
            // demo-sticky-mobile
            // '            position: sticky;\n' +
            '            background-color:' + ad.bg_color + ';\n' +
            '            width: 100%;\n' +
            '            padding: 8px 0;\n' +
            '            z-index: 2147483641;\n' +
            '            bottom: -1000px;\n' +
            '            left: 0px;\n' +
            '            right: 0px;\n' +
            '            transition: bottom .5s ease-in-out !important;\n' +
            '            text-align: center;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.reveal-bottom {\n' +
            '            bottom: 0px;\n' +
            '          }\n' +
            '          .ocm-sticky.reveal-bottom div[data-oau-code] {' +
            '            margin: 0 auto;' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.bottom .close-icon-container {\n' +
            '            position: absolute;\n' +
            '            top: -35px;\n' +
            `              ${ad.x_position === 'left' ? 'left: 4px;': 'right: 4px;'}\n` +
            '            z-index: 10;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.bottom .close-icon {\n' +
            '            display: inline-block;\n' +
            '            height: 32px;\n' +
            '            width: 32px;\n' +
            '            cursor: pointer;\n' +
            '            stroke: #fff;\n' +
            '          }\n' +
            '\n' +
            '          .ocm-sticky.bottom .progress {\n' +
            '            opacity: 0;\n' +
            '            stroke-dasharray: 0, 120;\n' +
            '          }')
    }

    fullScreenStyles(ad){
        this.utils.loadStyle('' +
            '            .ocm-sticky.ocm-fs {\n' +
            '                z-index: 2147483643;\n' +
            '                display: none;\n' +
            '                position: fixed;\n' +
            '                top: 0;\n'+
            '                left: 0;\n' +
            '                width: 100%;\n' +
            '                height: 100%;\n' +
            '                background: rgba(255, 255, 255, 0.7);\n' +
            '            }\n' +
            '\n' +
            '            .ocm-sticky.ocm-fs.active {\n' +
            '                display: block !important;\n' +
            '            }\n' +
            '\n' +
            '            .ocm-sticky.ocm-fs .ocm-sticky-container {\n' +
            '                z-index: 2147483643;\n' +
            '                display: flex;\n' +
            '                width: 100%;\n' +
            '                height: 100%;\n' +
            '                justify-content: center;\n' +
            '                align-items: center;\n' +
            '            }\n'+
            '\n' +
            '            .ocm-sticky.ocm-fs .ocm-sticky-container .ocm-sticky-content {\n' +
            '                z-index: 2147483643;\n' +
            '                display: flex;\n' +
            '            }\n' +
            '\n' +
            '            .ocm-sticky.ocm-fs .ocm-close-icon-container {\n' +
            '                z-index: 2147483644;\n' +
            '                display: flex;\n' +
            '                flex-direction: row;\n' +
            '                justify-content: flex-end;\n' +
            '                margin: 12px;\n' +
            '                position: absolute;\n' +
            '                top: 0;\n' +
            '                right: 0;\n' +
            '            }\n'+
            '\n' +
            '            body.no-scroll {\n' +
            '                overflow: hidden!important;\n' +
            '            }\n'
        );
    }

    deviceAllowed(conditions) {
        return (this.utils.is_mobile && conditions.device === 'mobile') ||
            (!this.utils.is_mobile && conditions.device === 'desktop' && this.utils.screen_width >= (conditions.min_width * this.utils.window.devicePixelRatio));
    }

    isExcluded(excluded_urls) {
        // If excluded url, then don't run
        if (typeof excluded_urls !== undefined && excluded_urls.length) {
            if (excluded_urls.find((url) => {
                return this.utils.window.location.href.includes(url);
            })) {
                return true;
            }
        }

        return false;
    }
}
