const LOG_PREFIX = '[OCM][StoryTeller] '
const CAIN_CDN_PATH = '//cdn.orangeclickmedia.com/tech/libs/deckard-cain.js'
// const Parser = require('rss-parser')

module.exports = class StoryTeller {
    utils
    config
    st_config
    conditions
    stickyConfig
    selector

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.st_config = config.services.story_teller
        this.conditions = (utils.is_mobile) ? this.st_config.mobile.conditions : this.st_config.desktop.conditions
        this.stickyConfig = (utils.is_mobile) ? this.st_config.mobile.sticky : this.st_config.desktop.sticky
        this.selector = null
    }

    run() {
        if (this.config.debug || this.st_config.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        this.setGlobals()

        this.loadIma().then(() => {
            if (!this.utils.allowPageType(this.conditions.page_types)) {
                if (this.config.debug || this.st_config.debug) {
                    console.log(LOG_PREFIX + 'Page type not allowed, terminating process',  this.conditions.page_types)
                }
                return
            }

            this.getStories().then(() => {
                const wrapper = this.createWrapper()

                const node = this.utils.determineInjectionTarget(
                    this.conditions.selector,
                    this.conditions.position,
                    this.conditions.count_gt,
                    this.conditions.words,
                    this.conditions.words_gt,
                )

                if (!node) {
                    if (this.config.debug || this.st_config.debug) {
                        console.info(LOG_PREFIX + 'selector NOT found, stopping process', node)
                    }
                    return;
                }

                if (wrapper) {
                    this.utils.injectTag(node, wrapper, this.conditions.place)
                    this.utils.waitFor('ADSQ.response', () => {
                        if ((this.config.debug || this.st_config.debug) && this.utils.window?.ADSQ?.response) {
                            console.log(LOG_PREFIX + 'ADSQ.response', this.utils.window?.ADSQ?.response)
                        }
                        this.populateWrapper(wrapper)
                    }, 50, () => {
                        this.populateWrapper(wrapper)
                    })
                }
            }).catch(() => {
                this.utils.window.OCM.ST.stories = null;
                this.utils.window.OCM.ST.video_url = '//cdn.orangeclickmedia.com/videos/clouds-720.mp4';
                this.utils.window.OCM.ST.source = 'video';
            });
        })
    }

    getStories() {
        return new Promise((resolve, reject) => {
            if (this.st_config.source === 'adsquirrel') {
                this.fetchStoriesFromAdsquirrel().then(() => {
                    resolve();
                }).catch(() => { // fallback to rss
                    if (this.config.debug || this.st_config.debug) {
                        console.log(LOG_PREFIX + 'Failed fetchStoriesFromAdsquirrel, falling back to fetchStoriesFromRss')
                    }
                    this.fetchStoriesFromRss().then(() => {
                        resolve();
                    }).catch(() => {
                        if (this.config.debug || this.st_config.debug) {
                            console.log(LOG_PREFIX + 'Failed fetchStoriesFromRss, falling back to cloud video')
                        }
                        reject();
                    })
                })
            } else if (this.st_config.source === 'rss') {
                if (this.st_config.stories_type === 'video') {
                    resolve();
                    return;
                }

                this.fetchStoriesFromRss().then(() => {
                    resolve();
                }).catch(() => {
                    if (this.config.debug || this.st_config.debug) {
                        console.log(LOG_PREFIX + 'Failed fetchStoriesFromRss, falling back to cloud video')
                    }
                    reject();
                })
            } else {
                // source = video
                reject();
            }
        });
    }

    fetchStoriesFromRss() {
        return new Promise(async (resolve, reject) => {
            if (!this.st_config.rss_url || this.st_config.rss_url === '') {
                reject()
            }

            this.utils.parseRss(this.st_config.rss_url).then((items) => {
                this.utils.window.OCM.ST.stories = []
                items.forEach((item) => {
                    if (this.config.debug || this.st_config.debug) {
                        console.log(LOG_PREFIX + 'Pushing to stories [', item.title, item.link, item.image, ']')
                    }

                    this.utils.window.OCM.ST.stories.push({
                        url: item.link,
                        title: item.title,
                        img: item?.image
                    })
                })

                resolve()
            }).catch((error) => {
                console.error(error)
                reject()
            })
        });
    }

    fetchStoriesFromAdsquirrel() {
        return new Promise((resolve, reject) => {
            this.utils.waitFor('ADSQ', () => {
                if (this.utils.window.ADSQ.response === null || this.utils.window.ADSQ.response.classified_as === null) {
                    if (this.config.debug || this.st_config.debug) {
                        console.warn(LOG_PREFIX + 'this.utils.window.ADSQ.response was null, rejecting fetchStoriesFromAdsquirrel')
                    }
                    reject();
                    return;
                }

                const hostname = 'hostname=' + this.utils.window.location.host + '&';
                const classification = 'classification=' + encodeURIComponent(this.utils.window.ADSQ.response.classified_as[0].split('/')[0]) + '&';
                // const brandSafe = 'brandSafe=' + this.utils.window.ADSQ.response.brandSafe + '&';
                // const sentiment = 'sentiment=' + this.utils.window.ADSQ.response.sentiment + '&';

                let query = '';
                query += hostname;
                query += classification;
                // query += brandSafe;
                // query += sentiment;
                query = query.substring(0, query.length - 1);
                query = btoa(query);

                fetch('https://api.adsquirrel.ai/stories?q=' + query, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'text/plain'
                    },
                    method: 'GET',
                })
                .then((resp) => resp.json())
                .then(data => {
                    this.utils.window.OCM.ST.stories = data?.filter(story => {
                        return story.url !== this.utils.window.location.protocol + '//' + this.utils.window.location.host + this.utils.window.location.pathname
                    })

                    if (typeof this.utils.window.OCM.ST.stories === 'undefined') {
                        if (this.config.debug || this.st_config.debug) {
                            console.warn(LOG_PREFIX + 'this.utils.window.OCM.ST.stories was undefined, rejecting fetchStoriesFromAdsquirrel')
                        }
                        reject()
                    }

                    if (this.config.debug || this.st_config.debug) {
                        console.log(LOG_PREFIX + 'this.utils.window.OCM.ST.stories is gg, resolving fetchStoriesFromAdsquirrel')
                    }
                    resolve();
                }).catch(() => {
                    if (this.config.debug || this.st_config.debug) {
                        console.warn(LOG_PREFIX + 'Could not fetch stories from api, rejecting fetchStoriesFromAdsquirrel')
                    }
                    reject();
                })
            }, 100, () => {
                if (this.config.debug || this.st_config.debug) {
                    console.warn(LOG_PREFIX + 'ADSQ response took too long to respond, rejecting fetchStoriesFromAdsquirrel')
                }
                reject()
            })
        })
    }

    setGlobals() {
        if (this.config.debug || this.st_config.debug) {
            console.log(LOG_PREFIX + 'Setting globals')
        }

        this.utils.window.OCM.ST = {
            ad_tag_url: this.st_config.ad_tag_url,
            delay_first_ad_request: this.st_config.delay_first_ad_request,
            load_offset: this.st_config.load_offset,
            source: this.st_config.source,
            rss_url: this.st_config.rss_url,
            stories_type: this.st_config.stories_type,
            video_url: this.st_config.video_url,
            captions_url: this.st_config.captions_url,
            debug: (this.config.debug || this.st_config.debug),
            color: this.st_config.color,
            link_target: this.st_config.link_target,
            link_text: this.st_config.link_text,
            branding: this.st_config.branding,
            sticky: this.stickyConfig,
            correlator: (new Date()).getTime(),
            device: this.utils.is_mobile ? 'mobile' : 'desktop'
        }
    }

    createWrapper() {
        if (this.config.debug || this.st_config.debug) {
            console.log(LOG_PREFIX + 'Creating wrapper div#ocm-st')
        }

        this.utils.loadStyle('#ocm-st { max-width:640px; display:block; margin:0 auto 1rem; position: relative; }')

        let wrapper = this.utils.window.document.createElement('div')
        wrapper.id = 'ocm-st'

        const styles = (this.utils.is_mobile) ? this.st_config.mobile.styles : this.st_config.desktop.styles
        const classLists = (this.utils.is_mobile) ? this.st_config.mobile.classes : this.st_config.desktop.classes

        if (styles && styles !== '') {
            wrapper.style = styles
        }

        if (classLists && classLists !== '') {
            wrapper.setAttribute('class', classLists)
        }

        return wrapper
    }

    loadIma() {
        return new Promise((resolve, reject) => {
            try {
                const scripts = this.utils.window.document.getElementsByTagName('script')[0]
                const s = this.utils.window.document.createElement('script')
                s.src = (this.config.debug || this.st_config.debug) ? "//imasdk.googleapis.com/js/sdkloader/ima3_debug.js" : "//imasdk.googleapis.com/js/sdkloader/ima3.js"
                scripts.parentNode.insertBefore(s, scripts)
                s.onload = () => {
                    resolve()
                }
            } catch (e) {
                reject()
            }
        })
    }

    populateWrapper(wrapper) {
        if (this.config.debug || this.st_config.debug) {
            console.log(LOG_PREFIX + 'Populating #ocm-st')
        }

        const script = this.utils.window.document.createElement('script')
        script.src = CAIN_CDN_PATH
        wrapper.append(script)
    }
}
