const LOG_PREFIX = '[OCM][FouAnalytics] '

const fouAnalytics = (utils, config) => {
    const fouScript = utils.window.document.createElement('script');
    fouScript.src = 'https://eu.b2c.com/api/init-224mtm6aorewjg83k2h.js';
    fouScript.async = true;
    fouScript.setAttribute('data-cfasync', 'true');
    utils.doc_head.append(fouScript);

    if (config.debug || config.services.fou_analytics.debug) {
        console.log(LOG_PREFIX + 'Loaded')
    }
}

module.exports = fouAnalytics;