let cmp = (config) => {
    if (config.debug || config.services.gpt.debug) {
        console.info('[OCM] Loading CMP library...')
    }

    if (!config.use_window_top) {
        console.error('[OCM][CMP] Use window top to run CMP service')
        return
    }

    (function () {
        let host = window.location.hostname;
        let element = document.createElement('script');
        let firstScript = document.getElementsByTagName('script')[0];
        let url = 'https://cmp.inmobi.com'
            .concat('/choice/', config.services.cmp.network_id, '/', host, '/choice.js?tag_version=V3');
        let uspTries = 0;
        let uspTriesLimit = 3;
        let uspInterval
        element.async = true;
        element.type = 'text/javascript';
        element.src = url;

        firstScript.parentNode.insertBefore(element, firstScript);

        function makeStub() {
            let TCF_LOCATOR_NAME = '__tcfapiLocator';
            let queue = [];
            let win = window;
            let cmpFrame;

            function addFrame() {
                let doc = win.document;
                let otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);

                if (!otherCMP) {
                    if (doc.body) {
                        let iframe = doc.createElement('iframe');

                        iframe.style.cssText = 'display:none';
                        iframe.name = TCF_LOCATOR_NAME;
                        doc.body.appendChild(iframe);
                    } else {
                        setTimeout(addFrame, 5);
                    }
                }
                return !otherCMP;
            }

            function tcfAPIHandler() {
                let gdprApplies;
                let args = arguments;

                if (!args.length) {
                    return queue;
                } else if (args[0] === 'setGdprApplies') {
                    if (
                        args.length > 3 &&
                        args[2] === 2 &&
                        typeof args[3] === 'boolean'
                    ) {
                        gdprApplies = args[3];
                        if (typeof args[2] === 'function') {
                            args[2]('set', true);
                        }
                    }
                } else if (args[0] === 'ping') {
                    let retr = {
                        gdprApplies: gdprApplies,
                        cmpLoaded: false,
                        cmpStatus: 'stub'
                    };

                    if (typeof args[2] === 'function') {
                        args[2](retr);
                    }
                } else {
                    if (args[0] === 'init' && typeof args[3] === 'object') {
                        args[3] = Object.assign(args[3], {tag_version: 'V3'});
                    }
                    queue.push(args);
                }
            }

            function postMessageEventHandler(event) {
                let msgIsString = typeof event.data === 'string';
                let json = {};

                try {
                    if (msgIsString) {
                        json = JSON.parse(event.data);
                    } else {
                        json = event.data;
                    }
                } catch (ignore) {
                }

                let payload = json.__tcfapiCall;

                if (payload) {
                    window.__tcfapi(
                        payload.command,
                        payload.version,
                        function (retValue, success) {
                            let returnMsg = {
                                __tcfapiReturn: {
                                    returnValue: retValue,
                                    success: success,
                                    callId: payload.callId
                                }
                            };
                            if (msgIsString) {
                                returnMsg = JSON.stringify(returnMsg);
                            }
                            if (event && event.source && event.source.postMessage) {
                                event.source.postMessage(returnMsg, '*');
                            }
                        },
                        payload.parameter
                    );
                }
            }

            while (win) {
                try {
                    if (win.frames[TCF_LOCATOR_NAME]) {
                        cmpFrame = win;
                        break;
                    }
                } catch (ignore) {
                }

                if (win === window.top) {
                    break;
                }
                win = win.parent;
            }
            if (!cmpFrame) {
                addFrame();
                win.__tcfapi = tcfAPIHandler;
                win.addEventListener('message', postMessageEventHandler, false);
            }
        }

        makeStub();

        let uspStubFunction = function () {
            let arg = arguments;
            if (typeof window.__uspapi !== uspStubFunction) {
                setTimeout(function () {
                    if (typeof window.__uspapi !== 'undefined') {
                        window.__uspapi.apply(window.__uspapi, arg);
                    }
                }, 500);
            }
        };

        let checkIfUspIsReady = function () {
            uspTries++;
            if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
                console.warn('USP is not accessible');
            } else {
                clearInterval(uspInterval);
            }
        };

        if (typeof window.__uspapi === 'undefined') {
            window.__uspapi = uspStubFunction;
            uspInterval = setInterval(checkIfUspIsReady, 6000);
        }
    })();
};

module.exports = cmp;
