require("intersection-observer")
const HeaderBidding = require('./header_bidding');

const LOG_PREFIX = '[OCM][LazyLoad] '

module.exports = class LazyLoad {
    utils
    config
    ll_config
    connection
    include_observer
    load_observer
    custom_observers
    include_offset
    load_offset
    offset_custom_observers
    hb
    scroll_direction
    last_scroll_top
    ticking

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.ll_config = config.services.lazyload
        this.scroll_direction = 'down'
        this.last_scroll_top = 0
        this.ticking = false
        this.include_offset = this.ll_config.include_offset
        this.load_offset = this.ll_config.load_offset
        this.offset_custom_observers = this.ll_config.load_offset
        this.connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection

        if (this.utils.serviceStates.header_bidding && this.utils.allowPageType(this.config.services.header_bidding.page_types)) {
            if (this.config.debug || this.ll_config.debug) {
                console.log(LOG_PREFIX + 'HB is active, instantiating HB')
            }

            this.hb = new HeaderBidding(utils, config);
        } else {
            this.hb = null
        }
    }

    async run() {
        if (this.config.debug || this.ll_config.debug) {
            console.log(LOG_PREFIX + 'running lazy loaded ads...')
        }

        if (this.hb) {
            if (this.config.debug || this.ll_config.debug) {
                console.log(LOG_PREFIX + 'HB is active, instantiating HB')
            }

            if (!this.utils.browser.isSafari || !this.utils.browser.isFacebookApp || !this.utils.browser.isIphone) {
                this.networkInfo();
            }

            await this.hb.prepare(true);
        }

        // call calculateDetectionPixels with params flagForCustomOffset = false, meaning that need recalculate include_offset
        this.calculateDetectionPixels(this.ll_config.load_offset, false)

        if (this.ll_config.version === 1) {
            await this.v1()
        } else {
            if (this.utils.browser.isSafari) {
                await this.v1()
            } else {
                await this.setupObservers()
                this.v2()
            }
        }
    }

    /**
     * Lazyload with events (v1)
     */
    async v1() {
        if (this.config.debug || this.ll_config.debug) {
            console.log(LOG_PREFIX + 'v1')
        }

        // Run initial detection
        await this.utils.gptPubAdsReady()
        this.detectNextAdUnits()

        // Setup scroll/resize events
        this.setupDetectionEvent()
    }

    /**
     * Lazyload with intersection observer (v2)
     */
    v2() {
        if (this.config.debug || this.ll_config.debug) {
            console.log(LOG_PREFIX + 'v2')
        }

        // Self calling function to detect newly defined ad units
        this.utils.gptPubAdsReady().then(() => {
            let detectNewAdUnitDefinitions = () => {
                // TODO: find a better way (event listener) to detect new slots)
                let nextAdUnits = this.utils.window.googletag.pubads().getSlots().map((slot) => {
                    let newElement = this.utils.window.document.querySelector('[id="' + slot.getSlotElementId() + '"]:not([data-oau-code]):not([data-lazyloaded-by-ocm]):not([data-lazyincluded-by-ocm])');
                    if (newElement) {
                        // Set the ad unit code attribute on the element for later usage
                        newElement.setAttribute('data-oau-code', slot.getAdUnitPath());
                        return newElement;
                    }

                    return null;
                });

                nextAdUnits = nextAdUnits.filter((el) => {
                    return el != null;
                });

                if (nextAdUnits.length) {
                    for (let i = 0; i < nextAdUnits.length; i++) {
                        // If ad unit has custom load offset = -1, then automatically lazyinclude it and observe it
                        if (this.ll_config.hasOwnProperty('ad_load_offsets') &&
                            this.ll_config.ad_load_offsets.length &&
                            this.ll_config.ad_load_offsets.filter((ad) => {
                                return (ad.path === nextAdUnits[i].getAttribute('data-oau-code') && ad.offset === -1)
                            }).length)
                        {
                            if (this.config.debug || this.ll_config.debug) {
                                console.log(LOG_PREFIX + 'll excluded element, combust instantly', nextAdUnits[i])
                            }
                            // if (typeof this.custom_observers[nextAdUnits[i].getAttribute('data-oau-code')] !== 'undefined') {
                            nextAdUnits[i].setAttribute('data-lazyincluded-by-ocm', '')
                            this.combust([nextAdUnits[i]])
                            // }
                        } else {
                            // Observe the rest gads elements
                            if (!nextAdUnits[i].hasAttribute('data-lazyincluded-by-ocm')) { // make sure it's not been included before
                                this.include_observer.observe(nextAdUnits[i]);
                            }
                        }
                    }
                }

                setTimeout(detectNewAdUnitDefinitions, 250);
            };

            detectNewAdUnitDefinitions()
        })
    }

    async setupObservers() {
        return new Promise((resolve, reject) => {
            this.custom_observers = []

            let include_observer_config = {
                root: null,
                // rootMargin: String(this.include_offset) + 'px 0px'
                rootMargin: this.include_offset && this.include_offset != -1 ? String(this.include_offset) + 'px 0px' : '0px 0px'
            };


            if (this.config.debug || this.ll_config.debug) {
                console.log(LOG_PREFIX + 'Include observer config: ', include_observer_config);
            }

            let load_observer_config = {
                root: null,
                // rootMargin: String(this.load_offset) + 'px 0px'
                rootMargin: this.load_offset && this.load_offset != -1 ? String(this.load_offset) + 'px 0px' : '0px 0px'
            };

            if (this.config.debug || this.ll_config.debug) {
                console.log(LOG_PREFIX + 'Load observer config: ', load_observer_config);
            }

            IntersectionObserver.prototype.POLL_INTERVAL = 100;

            // Custom Observers
            if (this.ll_config.hasOwnProperty('ad_load_offsets') && this.ll_config.ad_load_offsets.length) {
                for (const ad of this.ll_config.ad_load_offsets) {
                    // if custom offset > 0 - take custom offset else default load_offset
                    this.offset_custom_observers = (ad.offset && ad.offset > 0) ? ad.offset : this.ll_config.load_offset;
                    // if custom offset != -1 - call calculateDetectionPixels with custom offset like params
                    // and flagForCustomOffset = true, meaning that NOT need recalculate include_offset
                    // if (ad.offset === -1) {
                    //     let excluded = this.utils.window.document.querySelector('div[data-lazyincluded-by-ocm][data-oau-code="' + ad.path + '"]')
                    //     if (this.config.debug || this.ll_config.debug) {
                    //         console.log(LOG_PREFIX + 'll excluded element', excluded)
                    //     }
                    //     this.combust([excluded])
                    //     continue;
                    // }

                    let ad_offset = this.calculateDetectionPixels(ad.offset, true);

                    let combust_these = []
                    if (!ad.path || ad.path === '') {
                        continue;
                    }

                    let custom_observer_config = {
                        root: null,
                        rootMargin: ad_offset && ad_offset != -1 ? String(ad_offset) + 'px 0px': '0px 0px'
                    };

                    if (this.config.debug || this.ll_config.debug) {
                        console.log(LOG_PREFIX + 'Custom observer config: ', custom_observer_config);
                    }

                    this.custom_observers[ad.path] = new IntersectionObserver((entries, self) => {
                        let do_combust = false
                        entries.forEach((entry) => {
                            if (typeof entry.isVisible === 'undefined') {
                                entry.isVisible = true
                            }

                            if (entry.isIntersecting) {
                                if (this.config.debug || this.ll_config.debug) {
                                    console.log('intersecting', entry.target.getAttribute('data-oau-code'), entry.target.hasAttribute('data-lazyincluded-by-ocm'))
                                }
                                self.unobserve(entry.target);
                                combust_these.push(entry.target)
                                do_combust = true
                            }
                        })

                        // call combust to continue the process on the already included divs
                        if (do_combust) {
                            this.combust(combust_these)
                        }
                    }, custom_observer_config)
                }
            }

            // Load Observer
            this.load_observer = new IntersectionObserver((entries, self) => {
                let do_combust = false
                let combust_these = []
                entries.forEach((entry) => {
                    if (typeof entry.isVisible === 'undefined') {
                        entry.isVisible = true
                    }

                    if (entry.isIntersecting) {
                        self.unobserve(entry.target);
                        combust_these.push(entry.target)
                        do_combust = true
                    }
                })

                // call combust to continue the process on the already included divs
                if (do_combust) {
                    this.combust(combust_these)
                }
            }, load_observer_config)

            // Include observer
            this.include_observer = new IntersectionObserver((entries, self) => {
                entries.forEach((entry) => {
                    if (typeof entry.isVisible === 'undefined') {
                        entry.isVisible = true;
                    }

                    if (entry.isIntersecting) {
                        if (!entry.target.hasAttribute('data-lazyincluded-by-ocm')) {
                            entry.target.setAttribute('data-lazyincluded-by-ocm', '');
                            this.load_observer.observe(entry.target)
                        }
                    }

                    if (entry.target.hasAttribute('data-lazyincluded-by-ocm')) {
                        //here we stop observer the entry
                        self.unobserve(entry.target);
                    }
                })
            }, include_observer_config);

            resolve(true)
        })
    }

    async combust(elements) {
        // Clear elements array from null/empty/etc values
        elements = elements.filter(el => el)

        this.utils.window.OCM.pb_done = false

        if (this.config.debug || this.ll_config.debug) {
            console.log(LOG_PREFIX + 'Combusting..', elements)
        }

        // Fix for stickies
        let stickies = Array.from(this.utils.window.document.querySelectorAll('div[id^="ocm_sticky_"][data-lazyincluded-by-ocm]'))
        elements = (stickies.length) ? elements.concat(stickies) : elements

        // Change element state to loaded so it doesn't get picked up again
        // (this is the best place to do this to avoid double refreshes)
        if (elements.length) {
            elements.forEach((element) => {
                element.removeAttribute('data-lazyincluded-by-ocm')
                element.setAttribute('data-lazyloaded-by-ocm', '')
            })
        }

        let includedAdUnits = elements.map((element) => {
            return element.getAttribute('data-oau-code');
        });

        // Make sure it includes unique values
        includedAdUnits = includedAdUnits.filter((x, i, a) => a.indexOf(x) === i)

        let includedAdUnitIds = elements.map((element) => {
            return element.getAttribute('id');
        });

        await this.utils.gptPubAdsReady()

        let includedAdSlots = this.utils.window.googletag.pubads().getSlots().filter((slot) => {
            if (includedAdUnitIds.includes(slot.getSlotElementId())) {
                return true
            }

            return null
        })

        if (!includedAdSlots.length) {
            if (this.config.debug || this.ll_config.debug) {
                console.warn(LOG_PREFIX + 'No lazyincluded-by-com ad units found. Aborting ad calls.')
            }
            return
        }

        if (this.config.debug || this.ll_config.debug) {
            console.log(LOG_PREFIX + 'includedAdUnits', includedAdUnits)
            console.log(LOG_PREFIX + 'includedAdSlots', includedAdSlots)
        }

        // HB enabled
        if (this.hb && this.config.services.header_bidding.bidderTimeout > 1) {
            if (this.config.debug || this.ll_config.debug) {
                console.log(LOG_PREFIX + 'HB is active, refreshing ads')
            }

            // Determine if includedAdUnits are in the hb configured ad units
            let found_in_hb = this.findAdUnitsInHb(includedAdUnits)

            if (found_in_hb.length) {
                if (this.config.debug || this.ll_config.debug) {
                    console.log(LOG_PREFIX + 'Found ad unit configuration in HB, running hbRefresh')
                }
                await this.hbRefresh(found_in_hb, includedAdSlots)
            } else {
                if (this.config.debug || this.ll_config.debug) {
                    console.log(LOG_PREFIX + 'No ad unit configuration found in HB, running gptRefresh')
                }
                this.gptRefresh(includedAdSlots)
            }
        } else { // HB disabled
            if (this.config.debug || this.ll_config.debug) {
                console.log(LOG_PREFIX + 'refreshing ad slots')
            }

            this.gptRefresh(includedAdSlots)
        }
    }

    findAdUnitsInHb(includedAdUnits) {
        let hb_ad_units = []

        hb_ad_units = this.utils.window.OCM.ad_units.map((adunit) => {
            if (includedAdUnits.indexOf(adunit.code) > -1) {
                return adunit
            }
        }).filter(item => item !== undefined)

        return hb_ad_units
    }

    /**
     * Hb auction and refresh
     */
    async hbRefresh(found_in_hb, includedAdSlots) {
        this.utils.window.ocmpbjs.que.push(() => {
            try {
                // Reset pbjs adUnits to empty
                this.utils.window.ocmpbjs.removeAdUnit()

                this.utils.window.ocmpbjs.addAdUnits(found_in_hb);

                this.utils.window.ocmpbjs.requestBids({
                    adUnitCodes: found_in_hb.map((unit) => {
                        return unit.code;
                    }),
                    bidsBackHandler: (bids, timeout, auctionId) => {
                        this.hb.initAdServer(includedAdSlots, true)
                    },
                    timeout: this.hb.bidderTimeout
                });
            } catch (error) {
                console.error(error)
            }
        })
    }

    /**
     * Plain Googletag pubads refresh
     */
    gptRefresh(includedAdSlots) {
        this.utils.window.googletag.cmd.push(() => {
            for (const slot of includedAdSlots) {
                let el = this.utils.window.document.getElementById(slot.getSlotElementId())
                el.removeAttribute('data-lazyincluded-by-ocm')
                el.setAttribute('data-lazyloaded-by-ocm', '')
            }
            this.utils.window.googletag.pubads().refresh(includedAdSlots, { changeCorrelator: this.config.change_correlator !== undefined ? this.config.change_correlator : true });
        })
    }

    /**
     * Recalculate pixels threshold based on connection information (where applicable)
     */
    calculateDetectionPixels(adOffset, flagForCustomOffset) {
        let isSafariOrFirefoxOrIE = this.utils.window.navigator.userAgent.indexOf("Safari") !== -1 || this.utils.window.navigator.userAgent.indexOf("Firefox") !== -1 || (this.utils.window.navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true);
        if (this.config.debug || this.ll_config.debug) {
            console.info(LOG_PREFIX + 'isSafariOrFirefoxOrIE is:', isSafariOrFirefoxOrIE)
        }

        if (!isSafariOrFirefoxOrIE && (typeof this.connection === 'undefined' || typeof this.connection.effectiveType === 'undefined')) {
            return;
        }

        // firefox browser - No support effectiveType | support connection version > 31 (with specific settings)
        // safari browser - No support connection and effectiveType
        // IE browser - No support connection and effectiveType
        if (isSafariOrFirefoxOrIE && (typeof this.connection === 'undefined' || typeof this.connection.effectiveType === 'undefined')) {
            if (this.config.debug || this.ll_config.debug) {
                console.info(LOG_PREFIX + 'flagForCustomOffset is:', flagForCustomOffset)
            }

            // if flagForCustomOffset = false recalculate include_offset
            if (!flagForCustomOffset) {
                //calculate include offset
                this.include_offset = this.ll_config.include_offset;
                if (this.config.debug || this.ll_config.debug) {
                    console.info(LOG_PREFIX + 'old include offset', this.include_offset)
                }

                if (this.utils.is_mobile) {
                    this.include_offset = this.include_offset * 2
                }

                if (this.config.debug || this.ll_config.debug) {
                    console.log(LOG_PREFIX + 'Set include_offset to ' + this.include_offset + 'px');
                }
            }

            //calculate load offset
            this.load_offset = adOffset;
            if (this.config.debug || this.ll_config.debug) {
                console.info(LOG_PREFIX + 'old load offset', this.load_offset)
            }

            if (this.utils.is_mobile) {
                this.load_offset = this.load_offset * 2
            }

            if (this.config.debug || this.ll_config.debug) {
                console.log(LOG_PREFIX + 'Set load_offset to ' + this.load_offset + 'px');
            }

        } else {
            if (this.config.debug || this.ll_config.debug) {
                console.info(LOG_PREFIX + 'flagForCustomOffset is:', flagForCustomOffset)
            }
            // if flagForCustomOffset = false recalculate include_offset
            if (!flagForCustomOffset) {
                //calculate include offset
                this.include_offset = this.ll_config.include_offset;
                if (this.config.debug || this.ll_config.debug) {
                    console.info(LOG_PREFIX + 'old include offset', this.include_offset)
                }

                if (this.utils.is_mobile) {
                    this.include_offset = this.include_offset * 2
                }

                switch (this.connection.effectiveType) {
                    case '2g':
                        this.include_offset = Math.round(this.include_offset * 5); // rtt goes up to 1000ms from 4g's 200ms
                        break;
                    case '3g':
                        this.include_offset = Math.round(this.include_offset * 1.38); // rtt goes up to 275ms from 4g's 200ms
                        break;
                    default:
                        break;
                }

                if (this.config.debug || this.ll_config.debug) {
                    console.log(LOG_PREFIX + 'Set include_offset to ' + this.include_offset + 'px');
                }
            }

            //calculate load offset
            this.load_offset = adOffset;
            if (this.config.debug || this.ll_config.debug) {
                console.info(LOG_PREFIX + 'old load offset', this.load_offset)
            }

            if (this.utils.is_mobile) {
                this.load_offset = this.load_offset * 2
            }

            switch (this.connection.effectiveType) {
                case '2g':
                    this.load_offset = Math.round(this.load_offset * 5); // rtt goes up to 1000ms from 4g's 200ms
                    break;
                case '3g':
                    this.load_offset = Math.round(this.load_offset * 1.38); // rtt goes up to 275ms from 4g's 200ms
                    break;
                default:
                    break;
            }

            if (this.config.debug || this.ll_config.debug) {
                console.log(LOG_PREFIX + 'Set load_offset to ' + this.load_offset + 'px');
            }
        }

        return this.load_offset;
    }

    networkInfo() {
        if (typeof this.connection !== "undefined") {
            if (typeof this.connection.effectiveType !== 'undefined') {
                if (this.config.debug || this.ll_config.debug) {
                    this.connection.addEventListener("change", this.logNetworkInfo.apply(this, []));
                }

                // Recalculate pixels
                this.connection.addEventListener("change", () => {
                    // call calculateDetectionPixels with params offset_custom_observers (custom offset)
                    // and flagForCustomOffset = false, meaning that need recalculate include_offset else with default load_offset
                    this.calculateDetectionPixels(this.offset_custom_observers ? this.offset_custom_observers : this.ll_config.load_offset, false)
                })
            }
        }
    }

    logNetworkInfo() {
        if (this.ll_config.debug) {
            // Effective bandwidth estimate
            console.log(LOG_PREFIX + '     downlink: ' + this.connection.downlink + 'Mb/s');
            // Effective round-trip time estimate
            console.log(LOG_PREFIX + '          rtt: ' + this.connection.rtt + 'ms');
            console.log(LOG_PREFIX + '=========================================');
        }
    }

    /**
     * Setup event listener for scroll and resize, to detectNextAdUnits and dispatch newAuction events
     */
    setupDetectionEvent() {
        let event_list = ['scroll', 'size'];
        event_list.forEach((event) => {
            this.utils.window.addEventListener(event, this.utils.throttle(() => {
                let scroll_top = this.utils.window.pageYOffset || document.documentElement.scrollTop;
                if (scroll_top > this.last_scroll_top) {
                    this.scroll_direction = 'down'
                } else {
                    this.scroll_direction = 'up';
                }

                this.last_scroll_top = scroll_top <= 0 ? 0 : scroll_top;

                if (!this.ticking) {
                    let request_animation_frame = this.utils.window.requestAnimationFrame || setTimeout;
                    request_animation_frame(() => {
                        this.detectNextAdUnits();
                        this.ticking = false;
                    });
                }

                this.ticking = true;
            }, 1000))
        });
    }

    detectNextAdUnits() {
        if (this.config.debug || this.ll_config.debug) {
            console.log(LOG_PREFIX + 'Detecting next ad units');
        }

        let allAdSlots = [];
        let allAdElements = [];
        let includedAdElements = [];

        // Fetch defined ad slots
        allAdSlots = googletag.pubads().getSlots();
        let ad_element;
        // Get elements
        for (const slot of allAdSlots) {
            ad_element = document.getElementById(slot.getSlotElementId());
            if (ad_element) {
                allAdElements.push(ad_element);
                // Set data-oau-code
                if (!ad_element.hasAttribute('data-oau-code')) {
                    ad_element.setAttribute('data-oau-code', slot.getAdUnitPath());
                }
            }
        }

        // lazyIncludeIt one by one
        for (const element of allAdElements) {
            if (!element.hasAttribute('data-lazyincluded-by-ocm') && !element.hasAttribute('data-lazyloaded-by-ocm')) {
                if (this.lazyIncludeIt(element) !== false) {
                    element.setAttribute('data-lazyincluded-by-ocm', '');
                }
            }
        }

        includedAdElements = Array.from(this.utils.window.document.querySelectorAll('div[data-lazyincluded-by-ocm]'));

        // lazyLoadIt on the first occurrence
        if (typeof includedAdElements[0] !== 'undefined') {
            if (this.lazyLoadIt(includedAdElements[0]) !== false) {
                this.combust(includedAdElements)
            }
        }
    }

    lazyIncludeIt(element) {
        let bounding;

        if (element.style.display === 'none') {
            element.style.display = 'block';
            bounding = element.getBoundingClientRect();
            element.style.display = 'none';
        } else {
            bounding = element.getBoundingClientRect();
        }

        if (this.scroll_direction === 'down') {
            if (bounding.top >= 0 && bounding.top <= (this.utils.screen_height + this.ll_config.include_offset)) {
                return bounding.top;
            }
        } else if (this.scroll_direction === 'up') {
            if (bounding.bottom >= (this.ll_config.include_offset * -1)) {
                return bounding.top;
            }
        }

        return false;
    }

    lazyLoadIt(element) {
        let bounding;

        if (element.style.display === 'none') {
            element.style.display = 'block';
            bounding = element.getBoundingClientRect();
            element.style.display = 'none';
        } else {
            bounding = element.getBoundingClientRect();
        }

        let custom_offset = this.ll_config.load_offset
        let ad_load_offsets = this.ll_config.ad_load_offsets.filter((ad) => {
            return ad.path === element.getAttribute('data-oau-code')
        })

        if (ad_load_offsets.hasOwnProperty('offset')) {
            custom_offset = ad_load_offsets.offset
        }

        if (this.scroll_direction === 'down') {
            if (bounding.top >= 0 && bounding.top <= (this.utils.screen_height + custom_offset)) {
                return bounding.top;
            }
        } else if (this.scroll_direction === 'up') {
            if (bounding.bottom >= (custom_offset * -1)) {
                return bounding.top;
            }
        }

        return false;
    }
}
